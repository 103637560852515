import SidebarContent from 'components/Sidebar/SidebarContent';
import React from 'react';

import * as Styled from './SidebarDrawer.styles';

interface SidebarDrawerProps {
  open: boolean;
  onClose(): void;
}

const SidebarDrawer: React.FC<SidebarDrawerProps> = ({ open, onClose }) => {
  return (
    <>
      <Styled.SidebarDrawerContainer
        $open={open}
        className='d-flex flex-column align-items-center flex-grow-1 fixed-top fixed-bottom position-fixed px-2 py-4 shadow-sm'
      >
        <SidebarContent onClickMenuIcon={onClose} isExpanded={open} />
      </Styled.SidebarDrawerContainer>

      <Styled.SidebarDrawerOverlay
        $open={open}
        onClick={onClose}
        className='position-fixed bg-dark'
      />
    </>
  );
};

export { SidebarDrawer };
