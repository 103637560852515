import './styles.scss';

export interface SummaryProps {
  description: string;
  showReadMore: boolean;
}

const Summary: React.FC<SummaryProps> = ({ description, showReadMore }) => {
  return (
    <div className='Summary row'>
      <div className='text-truncate col-10'>{description}</div>
      {showReadMore && <div className='Summary__ReadMore col-2'>Read more</div>}
    </div>
  );
};

export default Summary;
