import Icon from 'components/Icon';
import React from 'react';

import './styles.scss';

export interface SectionHeaderProps {
  header: string;
  icon: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ header, icon }) => {
  return (
    <div className='d-flex flex-row align-items-center gap-2'>
      <Icon name={icon} />
      <span>{header}</span>
    </div>
  );
};

export default SectionHeader;
