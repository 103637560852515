import { DynamicComponentNames, WorkspaceConfiguration } from './typings';

export const workspaces: WorkspaceConfiguration[] = [
  {
    id: '4907ec95-683a-41da-b904-4f766f013c10',
    workspaceName: 'DRS',
    features: {
      hasSearch: true,
      hasChat: true,
    },
    search: {
      display: {
        searchSummary: true,
        relatedRequestThemes: true,
        searchResultPreview: true,
      },
      filters: [
        {
          id: '242b39b1-fcda-4cc4-a8a9-9d43eb372719',
          label: 'Date Range',
          filterType: 'date',
          sourceDataKey: 'closed_date',
        },
        {
          id: '3b52bf1a-2dc6-478b-801e-94769ce4fb50',
          filterType: 'boolean',
          label: 'Attachments',
          sourceDataKey: 'attachment_url',
        },
        {
          id: '03751127-f7a5-4af0-8e7f-b888dc2e8c06',
          filterType: 'list',
          label: 'Sector',
          sourceDataKey: 'sector',
        },
        {
          id: '20204390-cd15-4ca9-9792-2f3332c87009',
          filterType: 'list',
          label: 'Practice Area',
          sourceDataKey: 'practice_area',
        },
        {
          id: '16e73c96-2429-4c22-8a43-40b238851b4d',
          filterType: 'list',
          label: 'Attachment Type',
          sourceDataKey: 'attachment_type',
        },
        {
          id: '5e3d935d-fb5c-44d5-ae92-640ef2abb2b2',
          filterType: 'list',
          label: 'Location',
          sourceDataKey: 'location',
        },
      ],
      searchSummary: {
        prompt: 'Search Summary',
      },
      resultCardLayout: {
        rows: [
          {
            id: '241f707f-f7c0-4862-b67c-ab9d546c82ea',
            columns: [
              {
                id: '1e9e18ba-a209-486d-83ed-b68f5a743bbd',
                size: 11,
                componentName: DynamicComponentNames.TitleAsLink,
                requiredComponentRenderParams: [
                  {
                    paramName: 'title',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'generated_title',
                  },
                ],
              },
              // below section needs updated api response for attachment_url
              // {
              //   id: '2f624a49-bc98-4d37-bcf1-ad51b217e05176',
              //   size: 1,
              //   componentName: DynamicComponentNames.AttachmentIcon,
              //   requiredComponentRenderParams: [
              //     {
              //       paramName: 'field',
              //       paramType: 'dynamic',
              //       paramDynamicLookupKey: 'attachment_url',
              //     },
              //   ],
              // },
            ],
          },
          {
            id: 'b1f268b7-81d9-4360-802e-107886ad13bd',
            columns: [
              {
                id: 'a15a0925-0662-4068-98be-b88fe4f994b0',
                size: 8,
                componentName: DynamicComponentNames.LabelOnTop,
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'assigned_to_email',
                  },
                  {
                    paramName: 'label',
                    paramType: 'static',
                    paramStaticValue: 'Assigned to',
                  },
                ],
              },
              {
                id: 'e86b4ed5-12bd-45b8-9df3-f4bf2c6a0a83',
                size: 4,
                componentName: DynamicComponentNames.LabelOnTop,
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'closed_date',
                    paramFormat: 'DD/MM/YYYY',
                  },
                  {
                    paramName: 'label',
                    paramType: 'static',
                    paramStaticValue: 'Date Closed',
                  },
                ],
              },
            ],
          },
          {
            id: 'ab27a564-928a-4335-9ebb-ffd10470162f',
            columns: [
              {
                id: '916fa99c-3a19-49c6-8e45-19e8416bee98',
                size: 12,
                componentName: DynamicComponentNames.Summary,
                requiredComponentRenderParams: [
                  {
                    paramName: 'description',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'generated_summary',
                  },
                ],
              },
            ],
          },
          {
            id: '0fdc1717-5adb-4103-9772-f171d2c02ef98',
            columns: [
              {
                id: '14fbfde2-92e7-4cff-9740-b14ddd0df173',
                size: 12,
                componentName: DynamicComponentNames.Divider, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [],
              },
            ],
          },
        ],
      },
      resultPreviewLayout: {
        rows: [
          {
            id: '8d522f56-ad84-4f8b-b742-6a3ef7cbe61b',
            columns: [
              {
                id: '2f624a49-bc98-4d37-bcf1-ad51b217e05176',
                size: 1,
                componentName: DynamicComponentNames.EyeIcon, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [],
              },
              {
                id: '39a60ff7-5b29-483a-94f7-5ba882737c74',
                size: 10,
                componentName: DynamicComponentNames.Title, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'title',
                    paramType: 'static',
                    paramStaticValue: 'Ticket Preview',
                  },
                ],
              },
              {
                id: '2f624a49-bc98-4d37-bcf1-ad51b217e51076',
                size: 1,
                componentName: DynamicComponentNames.Button, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'ariaLabel',
                    paramType: 'static',
                    paramStaticValue: 'close preview',
                  },
                  {
                    paramName: 'className',
                    paramType: 'static',
                    paramStaticValue: 'btn-close',
                  },
                ],
              },
            ],
          },
          {
            id: '0fdc1717-5adb-4103-9771-f171dc02ef98',
            columns: [
              {
                id: '12fbfce2-92e7-4cff-9740-b14ddd0df173',
                size: 4,
                componentName: DynamicComponentNames.Pill, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'case_code',
                  },
                  {
                    paramName: 'icon',
                    paramType: 'static',
                    paramStaticValue: 'content_copy',
                  },
                ],
              },
              {
                id: '2fe58cff-fa68-407e-9561-764f55deee9b',
                size: 4,
                componentName: DynamicComponentNames.Pill, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'req_id',
                  },
                  {
                    paramName: 'icon',
                    paramType: 'static',
                    paramStaticValue: 'content_copy',
                  },
                ],
              },
            ],
          },
          {
            id: '0fdc17172-5adb-4103-9772-f171dc02ef98',
            columns: [
              {
                id: '14fbfce2-92e7-4cff-9740-b14ddd0df173',
                size: 10,
                componentName: DynamicComponentNames.TitleAsLink, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'title',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'generated_title',
                  },
                ],
              },
            ],
          },
          {
            id: '0fdc17173-5adb-4103-9772-f171dc02ef98',
            columns: [
              {
                id: '14fbfce2-92e7-4cff-9740-b14ddd0d173',
                size: 10,
                componentName: DynamicComponentNames.HtmlContent, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'content',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'generated_summary',
                  },
                ],
              },
            ],
          },
          // below section needs updated api response for attachment_url
          // {
          //   id: '0fdc17174-5adb-4103-9772-f171dc02ef98',
          //   columns: [
          //     {
          //       id: '14fbfe2-92e7-4cff-9740-b14ddd0df173',
          //       size: 10,
          //       componentName: DynamicComponentNames.SectionHeader, // putting this temporarily to satisfy the type
          //       requiredComponentRenderParams: [
          //         {
          //           paramName: 'header',
          //           paramStaticValue: 'Related materials and attachments',
          //           paramType: 'static',
          //         },
          //         {
          //           paramName: 'icon',
          //           paramStaticValue: 'description',
          //           paramType: 'static',
          //         },
          //       ],
          //     },
          //   ],
          // },
          // // {
          //   id: '0fdc1717-5adb-4103-9772-f1721dc02ef98',
          //   columns: [
          //     {
          //       id: '14fbfce2-92e7-4cff-9840-b14dd0df173',
          //       size: 12,
          //       requiredComponentRenderParams: [
          //         {
          //           paramName: 'fieldsArray',
          //           paramDynamicLookupKey: 'attachment_url',
          //           paramType: 'dynamic',
          //         },
          //       ],
          //       componentName: DynamicComponentNames.TextAslink,
          //     },
          //   ],
          // },
          {
            id: '0fdc1717-5adb-4103-9772-1f171dc02ef97',
            columns: [
              {
                id: '0fdc1717-5adb-4103-9772-f171dc02e1f98',
                size: 3,
                componentName: DynamicComponentNames.LabelOnTop,
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramDynamicLookupKey: 'created_date',
                    paramType: 'dynamic',
                    paramFormat: 'MMM DD,YYYY',
                  },
                  {
                    paramName: 'label',
                    paramStaticValue: 'Opening Date',
                    paramType: 'static',
                  },
                  {
                    paramName: 'icon',
                    paramStaticValue: 'calendar',
                    paramType: 'static',
                  },
                ],
              },
              {
                id: '0fdc11717-5adb-4103-9772-f171dc02ef98',
                size: 3,
                componentName: DynamicComponentNames.LabelOnTop,
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramDynamicLookupKey: 'closed_date',
                    paramType: 'dynamic',
                    paramFormat: 'MMM DD,YYYY',
                  },
                  {
                    paramName: 'label',
                    paramStaticValue: 'Closing Date',
                    paramType: 'static',
                  },
                  {
                    paramName: 'icon',
                    paramStaticValue: 'calendar',
                    paramType: 'static',
                  },
                ],
              },
              {
                id: '0fdc1717-5adb-4103-9772-f171dc02ef928',
                size: 3,
                componentName: DynamicComponentNames.LabelOnTop,
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramDynamicLookupKey: 'research_time_hours',
                    paramType: 'dynamic',
                    paramPostfix: 'Hours',
                  },
                  {
                    paramName: 'label',
                    paramStaticValue: 'Time spent',
                    paramType: 'static',
                  },
                  {
                    paramName: 'icon',
                    paramStaticValue: 'timer',
                    paramType: 'static',
                  },
                ],
              },
            ],
          },
          {
            id: '0fdc1717-5adb-4103-9772-1f171dc02ef98',
            columns: [
              {
                id: '0fdc1717-5adb-4103-9772-f171dc02e1f98',
                size: 6,
                componentName: DynamicComponentNames.LabelOnTop,
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramDynamicLookupKey: 'assigned_to_email',
                    paramType: 'dynamic',
                  },
                  {
                    paramName: 'label',
                    paramStaticValue: 'Assigned to',
                    paramType: 'static',
                  },
                  {
                    paramName: 'icon',
                    paramStaticValue: 'person',
                    paramType: 'static',
                  },
                ],
              },
              {
                id: '0fdc11717-5adb-4103-9772-f171dc02ef98',
                size: 6,
                componentName: DynamicComponentNames.LabelOnTop,
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramDynamicLookupKey: 'requestor_email',
                    paramType: 'dynamic',
                  },
                  {
                    paramName: 'label',
                    paramStaticValue: 'Requested By',
                    paramType: 'static',
                  },
                  {
                    paramName: 'icon',
                    paramStaticValue: 'people_outline',
                    paramType: 'static',
                  },
                ],
              },
            ],
          },
          {
            id: '0fdc1717-5adb-4103-9772-f171dc202ef98',
            columns: [
              {
                id: '14fbfce2-92e7-4cff-9740-b14dddf173',
                size: 10,
                componentName: DynamicComponentNames.SectionHeader, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'header',
                    paramStaticValue: 'Sources',
                    paramType: 'static',
                  },
                  {
                    paramName: 'icon',
                    paramStaticValue: 'info_circle',
                    paramType: 'static',
                  },
                ],
              },
            ],
          },
          {
            id: '0fdc1717-5adb-4103-9772-f2171dc02ef98',
            columns: [
              {
                id: '14fbf7e2-92e7-4cff-9740-b14ddd0df173',
                size: 5,
                componentName: DynamicComponentNames.Pill, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'sources',
                  },
                ],
              },
            ],
          },
          {
            id: '0fdc1717-5adb-4103-9772-f171d2c02ef98',
            columns: [
              {
                id: '14fbfde2-92e7-4cff-9740-b14ddd0df173',
                size: 12,
                componentName: DynamicComponentNames.Divider, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [],
              },
            ],
          },
          {
            id: '0fdc1717-5adb-4103-9772-f171dc02ef98',
            columns: [
              {
                id: '17fbfce2-92e7-4cff-9740-b14ddd0df173',
                size: 10,
                componentName: DynamicComponentNames.SectionHeader, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'header',
                    paramStaticValue: 'Practice Area/Sector',
                    paramType: 'static',
                  },
                  {
                    paramName: 'icon',
                    paramStaticValue: 'info_circle',
                    paramType: 'static',
                  },
                ],
              },
            ],
          },
          {
            id: '0fdc1717-5adb-4103-9772-f171d0c02ef98',
            columns: [
              {
                id: '16fbfce2-92e7-4cff-9740-b14ddd0df173',
                size: 5,
                componentName: DynamicComponentNames.Pill, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'practice_area',
                  },
                ],
              },
              {
                id: '15fbfce2-92e7-4cff-9740-b14ddd0df173',
                size: 5,
                componentName: DynamicComponentNames.Pill, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'sector',
                  },
                ],
              },
            ],
          },
          {
            id: '0fdc1717-5adb-4103-9772-f171d1c02ef98',
            columns: [
              {
                id: '13fbfce2-92e7-4cff-9740-b14ddd0df173',
                size: 12,
                componentName: DynamicComponentNames.Divider, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [],
              },
            ],
          },
        ],
      },
      noResultPreviewLayout: {
        rows: [
          {
            id: '8d522f56-ad84-4f8b-b742-6a3ef71cbe61a',
            columns: [
              {
                id: '8d522f56-ad84-4f8b-b742-6v3ef7cbe61a',
                size: 1,
                componentName: DynamicComponentNames.EyeIcon,
                requiredComponentRenderParams: [],
              },
              {
                id: '8d522f56-ad84-4f8b-b742-6c3ef7cbe61a',
                size: 10,
                componentName: DynamicComponentNames.Title,
                requiredComponentRenderParams: [
                  {
                    paramName: 'title',
                    paramStaticValue: 'Ticket Preview',
                    paramType: 'static',
                  },
                ],
              },
            ],
          },
          {
            id: '8d522f56-ad84-4f8b-b742-6a3df7cbe61a',
            columns: [
              {
                id: '8d522f56-ad84-4f8b-b742-6a3ef7cce61a',
                size: 12,
                componentName: DynamicComponentNames.CenteredImageWithText,

                requiredComponentRenderParams: [
                  {
                    paramName: 'icon',
                    paramStaticValue: 'illustration',
                    paramType: 'static',
                  },
                  {
                    paramName: 'text1',
                    paramStaticValue: 'This is the ticket preview',
                    paramType: 'static',
                  },
                  {
                    paramName: 'text2',
                    paramStaticValue:
                      "Click on the result you'd like to preview.",
                    paramType: 'static',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    chat: {},
  },
  {
    id: 'd6aad501-e273-4ad6-8046-cbfc3e7a71bd',
    workspaceName: 'PIPE',
    features: {
      hasSearch: true,
      hasChat: true,
    },
    search: {
      display: {
        searchSummary: true,
        relatedRequestThemes: true,
        searchResultPreview: true,
      },
      filters: [],
      searchSummary: {
        prompt: 'Search Summary',
      },
      resultCardLayout: {
        rows: [
          {
            id: 'cf507ae9-9d2e-41b3-accc-1142074b7861',
            columns: [
              {
                id: '920dd114-9552-4e98-b521-ffa73277d45e',
                size: 8,
                componentName: DynamicComponentNames.TitleAsLink,
                requiredComponentRenderParams: [
                  {
                    paramName: 'title',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'generated_title',
                  },
                  {
                    paramName: 'icon',
                    paramType: 'static',
                    paramStaticValue: 'CopyContent',
                  },
                  {
                    paramName: 'url',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'documentid',
                  },
                  {
                    paramName: 'target',
                    paramType: 'static',
                    paramStaticValue: '_blank',
                  },
                ],
              },
              {
                id: '09840f2b-3b0b-4b5e-ad28-4c2065cb771f',
                size: 4,
                componentName: DynamicComponentNames.AttachmentIcon,
                requiredComponentRenderParams: [],
              },
            ],
          },
          {
            id: '317d6f3f-0ce8-45cb-89ab-36a6402d3f8b',
            columns: [
              {
                id: '1bbe1293-641b-4c4d-af39-77f3bec24088',
                size: 3,
                componentName: DynamicComponentNames.LabelOnTop,
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'closed_date',
                  },
                  {
                    paramName: 'label',
                    paramType: 'static',
                    paramStaticValue: 'Sample Label',
                  },
                ],
              },
              {
                id: '206f9380-d334-464f-8b6d-76a9c237689f',
                size: 3,
                componentName: DynamicComponentNames.LabelOnTop,
                requiredComponentRenderParams: [
                  {
                    paramName: 'field',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'sddocname',
                  },
                  {
                    paramName: 'label',
                    paramType: 'static',
                    paramStaticValue: 'Sample Label',
                  },
                ],
              },
            ],
          },
          {
            id: '59376c02-72e2-43a4-8418-65312d054b61',
            columns: [
              {
                id: '6b6bc639-4f7d-4640-9150-2a9ecc9e4fa3',
                size: 12,
                componentName: DynamicComponentNames.Summary,
                requiredComponentRenderParams: [
                  {
                    paramName: 'description',
                    paramType: 'dynamic',
                    paramDynamicLookupKey: 'generated_summary',
                  },
                ],
              },
            ],
          },
        ],
      },
      resultPreviewLayout: {
        rows: [
          {
            id: '7c9232ba-bead-4bae-9ff0-7bc86533ed56',
            columns: [
              {
                id: '2f624a49-bc98-4d37-bcf1-ad510b217e5176',
                size: 2,
                componentName: DynamicComponentNames.HtmlContent, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [],
              },
              {
                id: '550afc50-8a57-4d47-94d5-b3c63afbe79b',
                size: 10,
                componentName: DynamicComponentNames.Summary, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [],
              },
            ],
          },
          {
            id: 'ca4bc6d0-f4cd-4cf5-b836-0ebca77427e2',
            columns: [
              {
                id: 'cd64ff52-0663-40de-9c7b-c17a5deacdc1',
                size: 2,
                componentName: DynamicComponentNames.Summary, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [],
              },
              {
                id: '051d3d96-9f26-491c-b9ca-8dc1fee9d02d',
                size: 10,
                componentName: DynamicComponentNames.Summary, // putting this temporarily to satisfy the type
                requiredComponentRenderParams: [],
              },
            ],
          },
        ],
      },
      noResultPreviewLayout: {
        rows: [
          {
            id: '8d522f56-ad84-4f8b-b742-6a3ef7cbe61b',
            columns: [
              {
                id: '8d522f56-ad84-4f8b-b742-6a3ef7cbe61b',
                size: 1,
                componentName: DynamicComponentNames.EyeIcon,

                requiredComponentRenderParams: [],
              },
              {
                id: '8d522f56-ad84-4f8b-b742-6a3ef7cbe61c',
                size: 10,
                componentName: DynamicComponentNames.Title,

                requiredComponentRenderParams: [
                  {
                    paramName: 'title',
                    paramStaticValue: 'Ticket Preview',
                    paramType: 'static',
                  },
                ],
              },
            ],
          },
          {
            id: '8d522f56-ad84-4f8b-b742-6a3ef7cbe61d',
            columns: [
              {
                id: '8d522f56-ad84-4f8b-b742-6a3ef7cbe61e',
                size: 12,
                componentName: DynamicComponentNames.CenteredImageWithText,
                requiredComponentRenderParams: [
                  {
                    paramName: 'icon',
                    paramStaticValue: 'illustration',
                    paramType: 'static',
                  },
                  {
                    paramName: 'text1',
                    paramStaticValue: 'This is the ticket preview',
                    paramType: 'static',
                  },
                  {
                    paramName: 'text2',
                    paramStaticValue:
                      "Click on the result you'd like to preview.",
                    paramType: 'static',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    chat: {},
  },
];
