import React from 'react';

export interface ButtonProps {
  className?: string;
  ariaLabel?: string;
  onClick?: Function;
}
const Icon: React.FC<ButtonProps> = ({ className, ariaLabel, onClick }) => {
  return (
    <button
      type='button'
      className={className}
      aria-label={ariaLabel}
      onClick={() => (onClick ? onClick() : null)}
    ></button>
  );
};

export default Icon;
