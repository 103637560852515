import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import oktaAuthService from 'oktaConfig';
import { RootState } from 'redux/store';
import { v4 as uuidv4 } from 'uuid';

import { popLastChatMessage, updateChatStreamData } from './slice';
import {
  ChatCompletionData,
  ChatMessage,
  ChatMessageRequestData,
  ChatMessageResponseData,
  ChatUserRoles,
  SystemMessageData,
  UserMessageData,
} from './typings';

export const sendChatMessage = createAsyncThunk<void, ChatMessage>(
  'chat/sendChatMessage',

  // payloadCreator's thunkAPI arg has a `signal` object
  // which can be used to cancel the request. See when
  // adding "Stop Generating" button to chat https://redux-toolkit.js.org/api/createAsyncThunk
  async (chatMessage, { rejectWithValue, dispatch, getState }) => {
    const requestId = uuidv4();
    console.info(`Starting send chat message for request ID: ${requestId}`);
    const functionStartTime = new Date().getTime();

    // immediately add users message to state
    dispatch(updateChatStreamData(chatMessage));

    // add assistant message to state to indicate loading
    setTimeout(() => {
      dispatch(
        updateChatStreamData({
          content: 'One moment please... \n\n',
          role: ChatUserRoles.Assistant,
        }),
      );
    }, 2000);

    // send users message to chat api and
    // stream assistant response to state
    const chatApiUrl = `${process.env.REACT_APP_CHAT_API_BASE_URL}/genai-chat/v1/chat/`;

    const requestBody: ChatMessageRequestData = {
      messages: [chatMessage],
      gen_options: {
        max_tokens: 200,
        stream: true,
      },
      request_id: requestId,
      consumer_id: 'KN',
      engine: 'gpt-4',
      context: '',
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${oktaAuthService.getAccessToken()}`,
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY || '',
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(chatApiUrl, requestOptions);
    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling chat API for request ID ${requestId}. Data: ${data}`,
      );
      return rejectWithValue(data);
    }

    const streamReader = response.body?.getReader();
    if (!streamReader) {
      return rejectWithValue(
        `Unable to get reader from response body, request ID: ${requestId}`,
      );
    }

    while (true) {
      const { done, value } = await streamReader.read();
      if (done) {
        console.info(`Chat stream completed for request ID: ${requestId}.`);
        break;
      }

      const assistantResponseText = new TextDecoder().decode(value);

      const [responseParseError, assistantResponseObject] =
        convertAssistantResponseTextToObject(assistantResponseText, requestId);
      if (responseParseError) {
        console.error(
          `Error parsing assistant, request ID: ${requestId}, error: ${responseParseError}`,
        );
        continue;
      }
      if (!assistantResponseObject) {
        continue;
      }

      const isSystemMessage =
        (assistantResponseObject.data as SystemMessageData).system_message !==
        undefined;
      if (isSystemMessage) {
        const systemMessageData =
          assistantResponseObject.data as SystemMessageData;

        console.info(
          `System message received for request ID: ${requestId}: `,
          systemMessageData,
        );

        if (systemMessageData.system_message === 'usage') {
          console.info(
            `Request usage info for request ID ${requestId}: ${JSON.stringify(
              systemMessageData.usage || {},
            )}`,
          );
        }

        continue;
      }

      let assistantText = '';

      // user message is simply the action the service
      // is currently taking, NOT the end users message
      // i.e "Searching KN Materials for xyz"
      const isUserMessage =
        (assistantResponseObject.data as UserMessageData).user_message !==
        undefined;
      if (isUserMessage) {
        assistantText = `${
          (assistantResponseObject.data as UserMessageData).user_message
        } \n\n`;
      }

      const isChatCompletion = isChatCompletionData(
        assistantResponseObject.data,
      );
      if (isChatCompletion) {
        assistantText = getAssistantTextFromCompletionData(
          assistantResponseObject.data as ChatCompletionData,
        );
      }

      const currentState = getState() as RootState;
      const currentChatMessages = currentState.chat.messages;
      const lastMessage = currentChatMessages[currentChatMessages.length - 1];

      handleAssistantResponse(lastMessage, assistantText, dispatch);
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.info(
      `Send Chat Message complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}`,
    );
  },
);

const handleAssistantResponse = (
  lastMessage: ChatMessage,
  assistantResponseText: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  if (lastMessage.role === ChatUserRoles.Assistant) {
    const concatenatedMessage = lastMessage.content + assistantResponseText;
    dispatch(popLastChatMessage());
    dispatch(
      updateChatStreamData({
        content: concatenatedMessage,
        role: ChatUserRoles.Assistant,
      }),
    );
  } else {
    dispatch(
      updateChatStreamData({
        content: assistantResponseText,
        role: ChatUserRoles.Assistant,
      }),
    );
  }
};

const convertAssistantResponseTextToObject = (
  content: string,
  requestId: string,
): [Error | null, ChatMessageResponseData | null] => {
  try {
    /*
    Content may come as mutliple lines, such as:
    
    data: {"id": "chatcmpl-8Dg9MRGaDACCaiY13b2zyGOxVPUHV", "choices": [{"delta": {"function_call": {"arguments": "{\n"}}}], "chunk_instance": 3}
    data: {"id": "chatcmpl-8Dg9MRGaDACCaiY13b2zyGOxVPUHV", "choices": [{"delta": {"function_call": {"arguments": " "}}}], "chunk_instance": 4}
    data: {"id": "chatcmpl-8Dg9MRGaDACCaiY13b2zyGOxVPUHV", "choices": [{"delta": {"function_call": {"arguments": " \""}}}], "chunk_instance": 5}
    data: {"id": "chatcmpl-8Dg9MRGaDACCaiY13b2zyGOxVPUHV", "choices": [{"delta": {"function_call": {"arguments": "queries"}}}], "chunk_instance": 6}
    
    For this reason, we need to ensure we convert each line to a JSON object.
    */
    const lines = content.split('\n');

    let mergedObject: ChatMessageResponseData | null = null;

    lines.forEach((line) => {
      // skip empty lines
      if (!line) {
        return;
      }

      const object = extractChatMessageDataFromContentString(line);
      if (!object) {
        console.log(
          `Unable to parse. Line: ${line}, request ID: ${requestId}. Skipping...`,
        );
        return;
      }

      // skip function_calls
      if (isChatCompletionData(object.data)) {
        const isFunctionCall =
          object.data.choices[0]?.delta?.function_call !== undefined;
        if (isFunctionCall) {
          console.log(
            `Skipping function call. Line: ${line}, request ID: ${requestId}. Skipping...`,
          );
          return;
        }
      }

      if (!mergedObject) {
        mergedObject = object;
        return;
      }

      // merge choices if line is a chat completion
      if (
        isChatCompletionData(object.data) &&
        isChatCompletionData(mergedObject.data)
      ) {
        mergedObject.data.choices = mergedObject.data.choices.concat(
          object.data.choices,
        );
        return;
      }
    });

    return [null, mergedObject];
  } catch (error) {
    console.error(
      'Error parsing data in convertAssistantResponseTextToObject: ',
      error,
      requestId,
    );

    return [error as Error, null];
  }
};

const extractChatMessageDataFromContentString = (
  content: string,
): ChatMessageResponseData | null => {
  try {
    const data = content.replace('data:', '').trim();
    const parsedData = JSON.parse(data) as
      | SystemMessageData
      | ChatCompletionData;
    return { data: parsedData };
  } catch (error) {
    console.error(
      'Error parsing data in extractChatMessageDataFromContentString: ',
      error,
    );
    return null;
  }
};

const getAssistantTextFromCompletionData = (
  data: ChatCompletionData,
): string => {
  const choices = data.choices;
  let assistantText = '';

  choices.forEach((choice) => {
    if (choice.delta?.content) {
      assistantText += choice.delta.content;
    }
  });

  return assistantText;
};

const isChatCompletionData = (data: any): data is ChatCompletionData => {
  return (data as ChatCompletionData).choices !== undefined;
};
