import TitleAsLink from 'components/TitleAsLink';
import { Origin } from 'components/WorkspaceConfigurations/typings';
import React from 'react';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

export interface TitleAsLinkDynamicWrapperProps extends DynamicWrapperProps {
  origin: Origin;
}

const TitleAsLinkDynamicWrapper: React.FC<TitleAsLinkDynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.title) {
    console.error(
      `TitleAsLinkDynamicWrapper: title or url is missing. title: ${props.title}, url: ${props.url}`,
    );
    return null;
  }

  return (
    <TitleAsLink
      title={props.title}
      url={props.url}
      target={props.target}
      fontSize={origin === 'resultCard' ? 18 : 16}
    />
  );
};

export default TitleAsLinkDynamicWrapper;
