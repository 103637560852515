import TextAsLink from 'components/TextAsLink';
import React from 'react';
import { AttachmentUrl } from 'redux/Retrieval/typings';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const TextAsLinkDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.fieldsArray) {
    console.error(
      `TextAsLinkDynamicWrapper: fieldsArray is missing. fieldsArray: ${props.fieldsArray}`,
    );
    return null;
  }

  const data = JSON.parse(props.fieldsArray).map((item: AttachmentUrl) => ({
    text: item.fileName,
    url: item.AttachmentUrl,
  }));

  return <TextAsLink fieldsArray={data} />;
};

export default TextAsLinkDynamicWrapper;
