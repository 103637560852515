import styled from 'styled-components';

export const SectionTitle = styled.div`
  color: ${(props) => props?.theme?.color?.gray200};
  font-size: 17px;
`;

export const ChipItem = styled.div`
  padding: 7px 12px;
  border-radius: 9999px;
  border: 1px solid ${(props) => props?.theme?.color?.gray200};
  font-size: 13px;
`;
