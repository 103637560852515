import SectionHeader from 'components/SectionHeader';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const SectionHeaderDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.header || !props.icon) {
    console.error(
      `SectionHeaderDynamicWrapper: label or icon is missing. label: ${props.header}, icon: ${props.icon}`,
    );
    return null;
  }

  return <SectionHeader header={props.header} icon={props.icon} />;
};

export default SectionHeaderDynamicWrapper;
