import CheckBox from 'components/CheckBox';
import { SearchFilter } from 'components/WorkspaceConfigurations/typings';
import { useTypedSearchParams } from 'hooks/useSearchParams';
import { useEffect, useState } from 'react';

interface CheckBoxDynamicWrapperProps {
  filter: SearchFilter;
  filterTitle: string;
  type: 'checkbox' | 'radio' | 'switch';
  isCheckedByDefault: boolean;
  filterValue: string;
  disabled: boolean;
}

const CheckBoxDynamicWrapper: React.FC<CheckBoxDynamicWrapperProps> = ({
  filter,
  filterTitle,
  filterValue,
  type,
  isCheckedByDefault,
  disabled,
}) => {
  const [searchParams, setSearchParams] = useTypedSearchParams();
  const [isChecked, setIsChecked] = useState(isCheckedByDefault);

  useEffect(() => {
    setIsChecked(isCheckedByDefault);
  }, [isCheckedByDefault]);

  const handleOnChange = () => {
    const currentSearchParamFilters = searchParams.filters;
    const currentSearchParamFiltersByFilterGroupName =
      currentSearchParamFilters[filter.sourceDataKey];

    if (currentSearchParamFiltersByFilterGroupName) {
      // check if the filterValue exists in the filter_values array.
      const currentSearchParamFilterValues =
        currentSearchParamFiltersByFilterGroupName[0].filter_values;
      const currentSearchParamFilterValueExists =
        currentSearchParamFilterValues.includes(filterValue);
      if (currentSearchParamFilterValueExists) {
        // delete the existing value before setting the new one.
        searchParams.deleteValueFromFilterValuesByFilterGroupNameAndValueName(
          filter.sourceDataKey,
          filterValue,
        );
        return;
      }
    }

    setSearchParams({
      filters: {
        [filter.sourceDataKey]: [
          {
            name: filter.sourceDataKey,
            type: filter.filterType,
            filter_values: [filterValue],
          },
        ],
      },
    });
  };

  return (
    <CheckBox
      label={filterTitle}
      type={type}
      isChecked={isChecked}
      onChange={handleOnChange}
      disabled={disabled}
    />
  );
};

export default CheckBoxDynamicWrapper;
