import React, { useEffect, useRef, useState } from 'react';
import { selectChatMessages } from 'redux/Chat/slice';
import { ChatUserRoles } from 'redux/Chat/typings';
import { useAppSelector } from 'redux/hooks';

import EmptyPlaceholder from './EmptyPlaceholder';
import styles from './index.module.scss';
import MessageItem from './MessageItem';

const Messages: React.FC = () => {
  const chatMessages = useAppSelector(selectChatMessages);
  const [userScrolledUp, setUserScrolledUp] = useState(false);
  const conversationListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let currentRef = conversationListRef.current;

    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [userScrolledUp]);

  useEffect(() => {
    if (!userScrolledUp) {
      scrollToBottom();
    }
  }, [chatMessages, userScrolledUp]);

  const handleScroll = () => {
    if (conversationListRef.current) {
      const scrollDifference =
        conversationListRef.current.scrollHeight -
        conversationListRef.current.clientHeight -
        conversationListRef.current.scrollTop;

      if (scrollDifference > 10) {
        setUserScrolledUp(true);
      } else {
        setUserScrolledUp(false);
      }
    }
  };

  const scrollToBottom = () => {
    if (conversationListRef.current) {
      conversationListRef.current.scrollTop =
        conversationListRef.current.scrollHeight;
    }
  };

  if (!chatMessages.length) {
    return <EmptyPlaceholder />;
  }

  return (
    <div className='flex-grow-1 overflow-auto d-flex' ref={conversationListRef}>
      <div className='flex-grow-1 d-flex flex-column'>
        {chatMessages.map((message, index) => (
          <div key={index} className={styles.prewrap}>
            <MessageItem
              message={message.content}
              assistant={message.role === ChatUserRoles.Assistant}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Messages;
