import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { conductRetrievalSearch } from './api';
import { RetrievalState } from './typings';

const initialState: RetrievalState = {
  searchQuery: '',
  results: [],
  filters: [],
  status: 'idle',
  error: null,
};

const retrievalSlice = createSlice({
  name: 'retrieval',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(conductRetrievalSearch.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(conductRetrievalSearch.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.results = action.payload.results;
      state.filters = action.payload.filters;
    });
    builder.addCase(conductRetrievalSearch.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as Error;
    });
  },
});

// selectors
export const selectRetrievalResults = (state: RootState) =>
  state.retrieval.results;

export const selectRetrievalFilters = (state: RootState) =>
  state.retrieval.filters;

export const selectRetrievalStatus = (state: RootState) =>
  state.retrieval.status;

export const selectRetrievalResultsCount = (state: RootState) =>
  state.retrieval.results.length;

export default retrievalSlice.reducer;
