import LoadingSpinnerSvg from 'assets/images/loading_spinner.svg';

interface LoadingProps {
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ className }) => {
  return <img src={LoadingSpinnerSvg} alt='Loading...' className={className} />;
};

export default Loading;
