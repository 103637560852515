import Summary from 'components/Summary';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const SummaryDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.description) {
    console.error('SummaryDynamicWrapper: description is missing.');
    return null;
  }

  const showReadMore = props.description.length > 100;

  return (
    <Summary description={props.description} showReadMore={showReadMore} />
  );
};

export default SummaryDynamicWrapper;
