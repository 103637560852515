import Icon, { IconSize } from 'components/Icon';
import { CustomIconNames } from 'components/WorkspaceConfigurations/typings';
import React, { useRef } from 'react';

import './styles.scss';

const { ContentCopy } = CustomIconNames;

export interface PillProps {
  showCopyIcon?: boolean;
  field: string;
  url?: string;
}

const Pill: React.FC<PillProps> = ({ field, url, showCopyIcon }) => {
  const textRef = useRef<HTMLDivElement>(null);

  const handleCopy = () => {
    const textToCopy = textRef.current?.innerText;
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    }
  };

  const isCommaSeperatedString = field.split(',').length > 1;

  return isCommaSeperatedString ? (
    <div className='d-flex justify-content-around'>
      {field.split(',').map((pillData) => (
        <div className='Pill'>{pillData}</div>
      ))}
    </div>
  ) : (
    <div
      className={`Pill ${showCopyIcon ? 'Pill__copyIcon' : ''}`}
      ref={textRef}
    >
      {/* if external image url is present */}
      {url && <img src={url} alt={url} width='15px' height='15px' />}
      <div>{field}</div>
      {showCopyIcon && (
        <Icon name={ContentCopy} size={IconSize.sm} onClick={handleCopy} />
      )}
    </div>
  );
};

export default Pill;
