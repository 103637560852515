import InputBox from './InputBox';
import Messages from './Messages';

const ChatBody = () => {
  return (
    <div className='d-flex flex-grow-1 flex-column'>
      <Messages />
      <InputBox />
    </div>
  );
};

export default ChatBody;
