import emptyChatImage from 'assets/images/empty_chat_image.png';

import styles from './index.module.scss';

const EmptyPlaceholder = () => {
  return (
    <div className='d-flex flex-grow-1 justify-content-center align-items-center flex-column'>
      <img
        src={emptyChatImage}
        alt='No chat messages to show'
        className={styles.emptyPlaceholderImage}
      />
      <div className='fw-medium'>Start Chatting.</div>
      <div className='text-secondary'>Ask anything in your own words.</div>
    </div>
  );
};

export default EmptyPlaceholder;
