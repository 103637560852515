import Icon, { IconSize } from 'components/Icon';
import Loading from 'components/Loading';
import { useTypedSearchParams } from 'hooks/useSearchParams';
import React, { useEffect, useState } from 'react';
import { getWorkspaceConfig } from 'redux/App/slice';
import { sendGenerativeMessage } from 'redux/Generative/api';
import {
  selectGenerativeLastReceivedUserQuery,
  selectGenerativeMessages,
  selectGenerativeStatus,
} from 'redux/Generative/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectRetrievalResults } from 'redux/Retrieval/slice';

import * as Styled from './SearchSummaryCard.styles';

const SearchSummaryCard: React.FC = () => {
  const dispatch = useAppDispatch();
  const retrievalResults = useAppSelector(selectRetrievalResults);
  const generativeStatus = useAppSelector(selectGenerativeStatus);
  const generativeMessages = useAppSelector(selectGenerativeMessages);
  const generativeLastRecievedMessage = useAppSelector(
    selectGenerativeLastReceivedUserQuery,
  );
  const workspace = useAppSelector(getWorkspaceConfig);
  const [searchParams] = useTypedSearchParams();
  const [open, setOpen] = useState(false);

  const toggleAccordionOpen = () => {
    // only run if the search query has changed
    if (searchParams.q !== generativeLastRecievedMessage) {
      dispatch(sendGenerativeMessage([retrievalResults, searchParams.q]));
    }

    setOpen(!open);
  };

  // to close card when new search is done
  useEffect(() => {
    if (open) {
      setOpen(!open);
    }
  }, [retrievalResults]);

  const splittedGenerativeMessages = generativeMessages[0]?.content.split(
    'Top researchers, analysts, and KT experts',
  );

  const formatGenerativeData = (data: string) => {
    const regex = /-[^]+?(?=\n|$)/g; // to convert generative data into string array
    const arrayData = data?.match(regex) || []; // to remove "-" from strings
    return arrayData.map((str) => str.substring(1));
  };

  const keyFindings =
    splittedGenerativeMessages?.length > 0
      ? formatGenerativeData(splittedGenerativeMessages[0])
      : [];

  const expertsAnalysts =
    splittedGenerativeMessages?.length > 0
      ? formatGenerativeData(splittedGenerativeMessages[1])
      : [];

  const showHeaderWIthIcon = (header: string, showHeaderWIthIcon: string) => {
    return (
      <div className='d-flex flex-row align-items-center gap-2'>
        <Icon size={IconSize.sm} name={showHeaderWIthIcon} />
        <span>{header}</span>
        {generativeStatus === 'loading' && <Loading className='max-width-20' />}
      </div>
    );
  };

  const navigateToKN = (data: string) => {
    let firstName = '';
    let lastName = '';
    if (data.includes('@')) {
      // when only email is generated for eg lastName.FirstName@test.com (1)
      const emailParts = data.split('@');
      const namePart = emailParts[0];
      firstName = namePart.split('.')[1];
      lastName = namePart.split('.')[0];
    }
    if (data.includes('@') && data.includes('<')) {
      // when name & email are generated for rg lastName, FirstName <lastName.FirstName@test.com> (1)
      const nameAndEmailParts = data.split('<');
      const namePart = nameAndEmailParts[0].split(',');
      firstName = namePart[1];
      lastName = namePart[0];
    }
    if (!data.includes('@') && !data.includes('<') && data.includes(',')) {
      // when only name is generated for eg lastName, FirstName (1)
      const nameParts = data.split('(');
      const namePart = nameParts[0].split(',');
      firstName = namePart[1];
      lastName = namePart[0];
    }
    if (firstName && lastName) {
      window.open(
        `${
          process.env.REACT_APP_KN_URL
        }/people?query=${firstName.trim()}%20${lastName.trim()}&enableAutoCorrect=true&resultsView=List%20View`,
        '_blank',
      );
    }
  };

  return (
    <>
      <Styled.SearchSummaryCardContainer className='rounded'>
        <div
          className='d-flex flex-row align-items-center px-4 gap-4 accordion-title-bar '
          onClick={toggleAccordionOpen}
        >
          <span>
            <Icon
              size={IconSize.md}
              name={open ? 'remove_circle' : 'add_circle'}
            />
          </span>
          <span className='label fw-medium'>
            {`${open ? 'Close' : 'Open'} Search Summary`}
          </span>
        </div>

        {open && (
          <div className='accordion-content'>
            {/* Key Findings section */}
            {showHeaderWIthIcon('Key Findings', 'info_circle')}
            <ul className='list-item-container'>
              {keyFindings.map((message, i) => (
                <li key={`${message}-${i}`}>{message}</li>
              ))}
            </ul>

            {/* Top DRS Analysts and KT Section */}
            {showHeaderWIthIcon('Top DRS Analysts and KT', 'prize')}
            <div className='d-flex flex-wrap mt-2'>
              {expertsAnalysts.map((message, i) => (
                <div className='details d-flex justify-content-center align-items-center'>
                  <div
                    key={`${message}-${i}`}
                    onClick={() => navigateToKN(message)}
                  >
                    {message}
                  </div>
                </div>
              ))}
            </div>

            {/* {generativeStatus === 'succeeded' && <ul className='list-item-container'>
              {generativeMessages.map((message, i) => (
                <li key={`${message.role}-${i}`}>{message.content}</li>
              ))}
            </ul>} */}

            {/* Sources is postponed until later */}
            {/* <div className='d-flex flex-row align-items-center gap-2'>
              <Icon size={IconSize.sm} name='info_circle' />
              <span>Sources</span>
            </div>

            <div className='mt-3 px-3 d-flex gap-2'>
              <ThemedButton variant='secondary'>Something</ThemedButton>
              <ThemedButton variant='secondary'>Something Else</ThemedButton>
            </div> */}
          </div>
        )}
      </Styled.SearchSummaryCardContainer>
    </>
  );
};

export default SearchSummaryCard;
