export enum ThemeOptions {
  Dark = 'dark',
  Light = 'light',
}

type ThemeColors = {
  primary: Color;
  secondary: Color;
  border: Color;
  text: Color;
  background: Color;
  indicator: Color;
  white: Color;
  gray200: Color;
  gray300: Color;
  gray400: Color;
  gray500: Color;
  gray700: Color;
  black: Color;
  purple100: Color;
  purple200: Color;
  purple300: Color;
  purple400: Color;
  purple500: Color;
};

type ThemeFontSize = {
  xs: string;
  sm: string;
  md: string;
  lg: string;
};

type ThemeBorderRadius = {
  small: string;
  medium: string;
  large: string;
  circle: string;
};

type ThemeDefaultSettings = {
  fontSize: ThemeFontSize;
  borderRadius: ThemeBorderRadius;
};

const themeDefaultSettings: ThemeDefaultSettings = {
  fontSize: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
  },
  borderRadius: {
    small: '5px',
    medium: '10px',
    large: '15px',
    circle: '50%',
  },
};

enum Color {
  Black = '#000000',
  Gray200 = '#F2F2F2',
  Gray300 = '#D4D4D4',
  Gray400 = '#B1B1B1',
  Gray500 = '#535353',
  Gray700 = '#323232',
  White = '#ffffff',
  Purple700 = '#3f1b68',
  Purple500 = '#5d289b',
  Purple400 = '#894bd1',
  Purple300 = '#a96cdd',
  Purple200 = '#e9ddf6',
  Purple100 = '#f7f1fd',
}

const darkTheme: Theme = {
  color: {
    primary: Color.Purple300,
    secondary: Color.Purple700,
    border: Color.Gray300,
    text: Color.White,
    background: Color.White,
    indicator: Color.Purple500,
    white: Color.White,
    gray200: Color.Gray200,
    gray300: Color.Gray300,
    gray400: Color.Gray400,
    gray500: Color.Gray500,
    gray700: Color.Gray700,
    black: Color.Black,
    purple100: Color.Purple100,
    purple200: Color.Purple200,
    purple300: Color.Purple300,
    purple400: Color.Purple400,
    purple500: Color.Purple500,
  },
  ...themeDefaultSettings,
};

const lightTheme: Theme = {
  color: {
    primary: Color.Purple300,
    secondary: Color.Purple700,
    border: Color.Gray300,
    text: Color.White,
    background: Color.White,
    indicator: Color.Purple500,
    white: Color.White,
    gray200: Color.Gray200,
    gray300: Color.Gray300,
    gray400: Color.Gray400,
    gray500: Color.Gray500,
    gray700: Color.Gray700,
    black: Color.Black,
    purple100: Color.Purple100,
    purple200: Color.Purple200,
    purple300: Color.Purple300,
    purple400: Color.Purple400,
    purple500: Color.Purple500,
  },
  ...themeDefaultSettings,
};

export const themes: Record<ThemeOptions, Theme> = {
  [ThemeOptions.Dark]: darkTheme,
  [ThemeOptions.Light]: lightTheme,
};

export type Theme = {
  color: ThemeColors;
  fontSize: ThemeFontSize;
  borderRadius: ThemeBorderRadius;
};
