import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import AppRoutes from 'components/Routes';
import { useNavigate } from 'react-router-dom';
import { selectCurrentTheme } from 'redux/App/slice';
import { useAppSelector } from 'redux/hooks';
import { ThemeProvider } from 'styled-components';
import { themes } from 'theme';

import oktaAuth from './oktaConfig';
import GlobalStyles from './stylesheets/globalStyles';

const App = () => {
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const currentTheme = useAppSelector(selectCurrentTheme);

  return (
    <ThemeProvider theme={themes[currentTheme]}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <GlobalStyles $currentTheme={currentTheme} />
        <AppRoutes />
      </Security>
    </ThemeProvider>
  );
};

export default App;
