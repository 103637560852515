import React from 'react';

import './styles.scss';

export interface TitleAsLinkProps {
  title: string;
  url?: string;
  target?: string;
  fontSize: number;
}

const TitleAsLink: React.FC<TitleAsLinkProps> = ({
  title,
  url,
  target,
  fontSize,
}) => {
  const handleClick = () => {
    if (url) {
      window.open(url, target);
    }
  };

  return (
    <div
      onClick={handleClick}
      className='TitleAsLink'
      style={{ fontSize: `${fontSize}px` }}
    >
      {title}
    </div>
  );
};

export default TitleAsLink;
