import NoSearchResultsPlaceHolderImage from 'assets/images/empty_search_results.svg';
import EmptyTicketPreviewPlaceHolderImage from 'assets/images/empty_ticket_preview.svg';
import React, { Fragment, useState } from 'react';
import { getWorkspaceConfig } from 'redux/App/slice';
import { useAppSelector } from 'redux/hooks';
import {
  selectRetrievalResults,
  selectRetrievalStatus,
} from 'redux/Retrieval/slice';
import { generateResultLayoutComponent } from 'utils/workspace';

import FilterPanel from '../FilterPanel';
import RelatedRequestThemes from '../RelatedRequestThemes';
import SearchSummaryCard from '../SearchSummaryCard';
import SearchBar from './SearchBar';
import * as Styled from './SearchPageContent.styles';
import SearchResultsLoading from './SearchResultsLoading';

const SearchPageContent: React.FC = () => {
  const retrievalLoading = useAppSelector(selectRetrievalStatus);
  const retrievalResults = useAppSelector(selectRetrievalResults);

  const workspace = useAppSelector(getWorkspaceConfig);
  const { resultCardLayout, resultPreviewLayout, noResultPreviewLayout } =
    workspace.search;
  const resultCardOrigin = 'resultCard';
  const previewLayoutOrigin = 'previewLayout';
  const [selectedResultIndex, setSelectedResultIndex] = useState<number | null>(
    null,
  );

  const clearSelectedResultIndex = () => {
    setSelectedResultIndex(null);
  };
  const callbackProps = { buttonOnClick: clearSelectedResultIndex };

  const noSearchResultsElements = (
    <div className='row'>
      <div className='col-12 mt-5'>
        <div className='d-flex flex-grow-1 justify-content-center align-items-center flex-column'>
          <img src={NoSearchResultsPlaceHolderImage} alt='No search results' />
          <div className='fw-medium mt-3'>Welcome and start exploring.</div>
          <div className='text-secondary'>
            Start searching to populate the results.
          </div>
        </div>
      </div>
    </div>
  );

  const searchResultsElements = (
    <>
      {workspace.search.display.searchSummary && <SearchSummaryCard />}
      <div className='row mt-4'>
        <div className='col-6 '>
          {retrievalResults?.map((retrievalMaterial, i) => (
            <div
              key={retrievalMaterial.documentid}
              onClick={() => setSelectedResultIndex(i)}
              className='ResultCard-layout'
            >
              {generateResultLayoutComponent(
                resultCardLayout,
                retrievalMaterial,
                resultCardOrigin,
              )}
            </div>
          ))}
        </div>
        {selectedResultIndex !== null && (
          <div className='col-6 mt-3 Preview-layout'>
            <Fragment>
              {generateResultLayoutComponent(
                resultPreviewLayout,
                retrievalResults[selectedResultIndex],
                previewLayoutOrigin,
                callbackProps,
              )}
            </Fragment>
          </div>
        )}

        {/* empty preview layout */}
        {selectedResultIndex === null && (
          <div className='col-6 mt-3 Preview-layout'>
            <Fragment>
              {generateResultLayoutComponent(
                noResultPreviewLayout,
                retrievalResults[retrievalResults.length - 1],
                previewLayoutOrigin,
                callbackProps,
              )}
            </Fragment>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div>
      <SearchBar />

      <Styled.ContentContainer>
        <RelatedRequestThemes />

        <div className='content-wrapper mt-3'>
          <div className='row'>
            <div className='col-12 col-md-4 col-lg-2 filter-panel-column'>
              <FilterPanel />
            </div>
            <div className='col-12 col-md-8 col-lg-10'>
              {retrievalLoading === 'loading' && <SearchResultsLoading />}

              {retrievalLoading !== 'loading' &&
                retrievalResults?.length === 0 &&
                noSearchResultsElements}

              {retrievalResults?.length > 0 && searchResultsElements}
            </div>
          </div>
        </div>
      </Styled.ContentContainer>
    </div>
  );
};

export default SearchPageContent;
