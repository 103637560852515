import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  handleStartDateChange: (date: Date) => void;
  handleEndDateChange: (date: Date) => void;
  handleMonthChange: (date: Date) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  handleMonthChange,
}) => {
  const defaultYear = new Date();
  defaultYear.setFullYear(defaultYear.getFullYear() - 1);
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div className='inline-block'>
        <label>From</label>
        <DatePicker
          selected={defaultYear}
          onChange={(date) => handleStartDateChange(date as Date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          showPopperArrow={false}
          inline
          dateFormat={'MM/yyyy'}
          showMonthYearPicker
          scrollableYearDropdown
        />
      </div>
      <div className='inline-block'>
        <label>To</label>
        <DatePicker
          selected={endDate}
          onChange={(date) => handleEndDateChange(date as Date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          showPopperArrow={false}
          inline
          dateFormat={'MM/yyyy'}
          showMonthYearPicker
          scrollableYearDropdown
          onMonthChange={handleMonthChange}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
