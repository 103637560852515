import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 65px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.gray300}`};
  margin-top: 13px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-left: 15px;
  padding-right: 65px;
`;

export const TextWrapper = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0.25em;
  text-align: left;
`;
