import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

import MenuIconButton from './MenuIconButton';
import MenuItem from './MenuItem';
import * as Styled from './Sidebar.styles';

interface SidebarContentProps {
  isExpanded: boolean;
  onClickMenuIcon(): void;
}

const SidebarContent: React.FC<SidebarContentProps> = ({
  isExpanded,
  onClickMenuIcon,
}) => {
  const location = useLocation();
  const currentPathname = location.pathname;

  return (
    <>
      <div
        className={classNames({
          'w-100 d-flex justify-content-end align-items-center': isExpanded,
        })}
      >
        <MenuIconButton isExpanded={isExpanded} onClick={onClickMenuIcon} />
      </div>

      <Styled.SidebarMenuListContainer
        $expanded={isExpanded}
        className='d-flex flex-column gap-4 mt-4'
      >
        <MenuItem
          icon='search'
          label={'Search'}
          isExpanded={isExpanded}
          active={currentPathname === '/'}
          route='/'
        />
        <MenuItem
          icon='chat'
          label={'Chat'}
          isExpanded={isExpanded}
          active={currentPathname === '/chat'}
          route='/chat'
        />
        <MenuItem
          icon='settings'
          label={'Settings'}
          active={currentPathname === '/settings'}
          route='/settings'
          isExpanded={isExpanded}
        />
      </Styled.SidebarMenuListContainer>
    </>
  );
};

export default SidebarContent;
