import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import appReducer from 'redux/App/slice';
import chatReducer from 'redux/Chat/slice';
import counterReducer from 'redux/Counter/slice';
import generativeReducer from 'redux/Generative/slice';
import retrievalReducer from 'redux/Retrieval/slice';

const rootReducer = combineReducers({
  counter: counterReducer,
  app: appReducer,
  chat: chatReducer,
  retrieval: retrievalReducer,
  generative: generativeReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
