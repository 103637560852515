import { useSearchParams as useReactRouterSearchParams } from 'react-router-dom';
import { RetreivalFilter } from 'redux/Retrieval/typings';

export interface SearchParams {
  q: string;
  filters: SearchParamFilters;
}

export interface SearchParamFilters {
  [key: string]: RetreivalFilter[];
}

export const decodeSearchParamFilters = (
  encodedString: string,
): SearchParamFilters => {
  if (!encodedString) {
    return {};
  }

  const encodedFilters = encodedString.split('|');
  const decodedFilters: SearchParamFilters = {};

  encodedFilters.forEach((encodedFilterGroup) => {
    const filterGroupArray = encodedFilterGroup.split('$');

    filterGroupArray.forEach((encodedFilterString) => {
      const [
        encodedKey,
        encodedName,
        encodedType,
        encodedValues,
        encodedPredicate,
      ] = encodedFilterString?.split(':');
      const filterValues = encodedValues
        ?.split(',')
        .map((value) => decodeURIComponent(value));

      const key = decodeURIComponent(encodedKey);
      const name = decodeURIComponent(encodedName);
      const type = decodeURIComponent(encodedType) as RetreivalFilter['type'];

      if (!decodedFilters[key]) {
        decodedFilters[key] = [];
      }

      const filter: RetreivalFilter = {
        name,
        type,
        filter_values: filterValues,
      };

      if (encodedPredicate) {
        filter.predicate = decodeURIComponent(
          encodedPredicate,
        ) as RetreivalFilter['predicate'];
      }

      decodedFilters[key].push(filter);
    });
  });

  return decodedFilters;
};

export const encodeSearchParamFilters = (
  searchParamFilters: SearchParamFilters,
): string => {
  const filterKeys = Object.keys(searchParamFilters);

  /*
      This allows us to encode multiple filters for the same key.
      For example:
      "material_type": [
          {
              "name": "material_type",
              "type": "list",
              "filter_values": [
                  "Research Materials",
                  "Agile Methodology"
              ]
          },
          {
              "name": "filename",
              "type": "regex",
              "filter_values": [
                  ".pptx"
              ]
          }
      ]
      These should be encoded in such a way that we can decode them
      and know that they are both for the "material_type" key. This
      is done by using the $ symbol to separate the filters for the
      same key. So the above example would be encoded as:
      "material_type:list:Research Materials,Agile Methodology$filename:regex:.pptx"
  */
  const encodedFilters = filterKeys.map((key) => {
    const currentFilterGroup = searchParamFilters[key];
    const encodedFilters = currentFilterGroup.map((filter) => {
      const encodedKey = encodeURIComponent(key);
      const encodedName = encodeURIComponent(filter.name);
      const encodedType = encodeURIComponent(filter.type);
      const encodedFilterValues = filter.filter_values
        .map((value) => encodeURIComponent(value))
        .join(',');

      let encodedFilterString = `${encodedKey}:${encodedName}:${encodedType}:${encodedFilterValues}`;

      if (filter?.predicate) {
        encodedFilterString = `${encodedFilterString}:${filter.predicate}`;
      }

      return encodedFilterString;
    });

    const joinedFilterGroupString = encodedFilters.join('$');

    return joinedFilterGroupString;
  });

  /*
      This joins all of the encoded filters together with the | symbol.
      The | in the string indicates that the filters are for different
      keys. For example:
      "material_type:list:Research Materials,Agile Methodology$filename:regex:.pptx|enddate:date:2013-03-01:<="
      We know that the first filter is for the "material_type" key and
      the second filter is for the "enddate" key by looking at the
      string before the pipe.       
  */
  let joinedFilters = encodedFilters.join('|');

  // remove pipe from first and last character of string if it exists
  // this is to prevent a url that looks like this:
  // https://foo.bar/?q=*&filters=|enddate:date:2013-03-01:<=
  joinedFilters = joinedFilters.replace(/^\|/, '');
  joinedFilters = joinedFilters.replace(/\|$/, '');

  return joinedFilters;
};

export const combineFilters = (
  existingFilters: SearchParamFilters,
  newFilters: SearchParamFilters,
): SearchParamFilters => {
  const result: SearchParamFilters = { ...existingFilters };

  // Combine existing filters with new filters
  Object.keys(newFilters).forEach((key) => {
    if (result[key]) {
      // If filter already exists, merge filter_values arrays
      result[key] = result[key].map((existingFilter) => {
        const newFilter = newFilters[key].find(
          (newFilter) => newFilter.name === existingFilter.name,
        );
        if (newFilter) {
          return {
            ...existingFilter,
            filter_values: [
              ...existingFilter.filter_values,
              ...newFilter.filter_values,
            ],
          };
        }
        return existingFilter;
      });
    } else {
      // If filter does not exist, add it to the result
      result[key] = newFilters[key];
    }
  });

  return result;
};

export const removeEmptyFilters = (
  filters: SearchParamFilters,
): SearchParamFilters => {
  const filtersCopy = { ...filters };

  // remove filters that have no filter_values
  // which indicates that the user removed that filter
  Object.keys(filtersCopy).forEach((key) => {
    const filterGroup = filtersCopy[key];

    // remove empty string values from the filter_values
    // this can happen for select filters when a user unslects their option
    const filterGroupWithEmptyFilterValuesStringsRemoved = filterGroup.map(
      (filter) => {
        filter.filter_values = filter.filter_values.filter(
          (value) => value !== '',
        );

        return filter;
      },
    );

    // filter out any filters that have no filter_values
    // i.e filter_values: []
    const filterGroupWithEmptyFilterValuesRemoved =
      filterGroupWithEmptyFilterValuesStringsRemoved.filter(
        (filter) => filter.filter_values.length,
      );

    // if the filter group is empty, remove it from the combined filters
    if (!filterGroupWithEmptyFilterValuesRemoved.length) {
      delete filtersCopy[key];
      return;
    }

    filtersCopy[key] = filterGroupWithEmptyFilterValuesRemoved;
  });

  return filtersCopy;
};

export const useTypedSearchParams = () => {
  const [searchParams, setSearchParams] = useReactRouterSearchParams();

  const typedSearchParams = {
    q: searchParams.get('q') || '',
    filters: decodeSearchParamFilters(searchParams.get('filters') || ''),

    deleteSearchParamFilterByFilterGroupName: (filterGroupName: string) => {
      const filters = decodeSearchParamFilters(
        searchParams.get('filters') || '',
      );

      delete filters[filterGroupName];

      const encodedFilters = encodeSearchParamFilters(filters);

      if (encodedFilters === '') {
        searchParams.delete('filters');
      } else {
        searchParams.set('filters', encodedFilters);
      }

      setSearchParams(searchParams);
    },
    deleteSearchParamByKey: (key: string) => {
      searchParams.delete(key);
      setSearchParams(searchParams);
    },
    deleteValueFromFilterValuesByFilterGroupNameAndValueName: (
      groupName: string,
      valueName: string,
    ) => {
      const filters = decodeSearchParamFilters(
        searchParams.get('filters') || '',
      );

      const filterGroup = filters[groupName];

      const updatedFilterGroup = filterGroup.map((filter) => {
        filter.filter_values = filter.filter_values.filter(
          (value) => value !== valueName,
        );

        return filter;
      });

      // if filter_values is empty, remove the filter
      if (!updatedFilterGroup[0].filter_values.length) {
        delete filters[groupName];
      } else {
        filters[groupName] = updatedFilterGroup;
      }

      const encodedFilters = encodeSearchParamFilters(filters);

      if (encodedFilters === '') {
        searchParams.delete('filters');
      } else {
        searchParams.set('filters', encodedFilters);
      }

      setSearchParams(searchParams);
    },
  };

  /**
   *
   * @param params the search params to use
   * @param replace optional flag for whether or not we should delete the existing search params before setting the new ones
   */
  const setTypedSearchParams = (
    params: Partial<SearchParams>,
    replace?: boolean,
  ) => {
    // q param
    if (params.q !== undefined) {
      searchParams.set('q', params.q);
    }

    // filters param
    if (params.filters !== undefined) {
      // in order to not overwrite existing filters, we need to combine
      // the existing filters with the new filters.
      const existingFilters = decodeSearchParamFilters(
        searchParams.get('filters') || '',
      );

      if (replace) {
        // loop through existingFilters and delete
        // any existing filters that have the same group
        // name as the incoming filters
        Object.keys(existingFilters).forEach((filterGroupName) => {
          // typedSearchParams.deleteSearchParamFilterByFilterGroupName(filterGroupName);
          delete existingFilters[filterGroupName];
        });
      }

      const combinedFilters = combineFilters(existingFilters, params.filters);

      const filtersWithEmptiesRemoved = removeEmptyFilters(combinedFilters);

      const encodedFilters = encodeSearchParamFilters(
        filtersWithEmptiesRemoved,
      );

      if (encodedFilters === '') {
        searchParams.delete('filters');
      } else {
        searchParams.set('filters', encodedFilters);
      }
    }

    setSearchParams(searchParams);
  };

  return [typedSearchParams, setTypedSearchParams] as const;
};
