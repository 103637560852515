import Pill from 'components/Pill';
import { RequiredRenderParam } from 'components/WorkspaceConfigurations/typings';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const PillDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);
  if (!props.field) {
    console.error(
      `PillDynamicWrapper: pill data is missing. content: ${props.field}`,
    );
    return null;
  }

  return (
    <Pill
      field={props.field}
      url={props.url}
      showCopyIcon={props.icon ? true : false}
    />
  );
};

export default PillDynamicWrapper;
