import Button from 'components/Button';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const ButtonDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
  callbackProps,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  return (
    <Button
      ariaLabel={props.ariaLabel}
      className={props.className}
      onClick={callbackProps && callbackProps.buttonOnClick}
    />
  );
};

export default ButtonDynamicWrapper;
