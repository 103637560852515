import styled from 'styled-components';

export const FilterPanelTitleContainer = styled.div`
  border-bottom: 1px solid ${(props) => props?.theme?.color?.gray200};

  .left-content {
    .label {
      color: ${(props) => props?.theme?.color?.gray400};
      font-size: ${(props) => props?.theme?.fontSize?.lg};
    }
  }

  .right-content {
    color: ${(props) => props?.theme?.color?.gray300};
    font-size: ${(props) => props?.theme?.fontSize?.sm};
  }
`;

export const FilterAccordionItemContainer = styled.div`
  border-bottom: 1px solid ${(props) => props?.theme?.color?.gray200};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) => props?.theme?.color?.purple100};
  }

  .arrow-icon {
    width: 2rem;
    height: 2rem;
    fill: ${(props) => props?.theme?.color?.gray300};
    transition: fill 0.3s ease-in-out;
  }

  .arrow-icon-rotate-down {
    transform: rotate(180deg);
  }
`;

export const FilterCheckboxItemContainer = styled.div`
  border-bottom: 1px solid ${(props) => props?.theme?.color?.gray200};
  padding: 16px 7px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) => props?.theme?.color?.purple100} !important;
  }

  .label {
    color: ${(props) => props?.theme?.color?.gray400};
  }
`;
