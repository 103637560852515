import classNames from 'classnames';
import React, { useState } from 'react';

import * as Styled from './FilterPanel.styles';

interface FilterAccordionItemProps {
  label: string;
  children?: React.ReactNode;
}

const FilterAccordionItem: React.FC<FilterAccordionItemProps> = ({
  label,
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Styled.FilterAccordionItemContainer className='pt-2 pb-2'>
      <button
        type='button'
        disabled={!children}
        className='d-flex align-items-center justify-content-between w-100 mb-1 border-0 bg-transparent'
        onClick={() => setOpen(!open)}
      >
        <div className='label fs-8 fw-500'>{label}</div>
        <svg
          className={classNames('arrow-icon', {
            'arrow-icon-rotate-down': open,
          })}
          viewBox='0 0 32 32'
        >
          <path d='M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z' />
        </svg>
      </button>

      {children && open && <div>{children}</div>}
    </Styled.FilterAccordionItemContainer>
  );
};

export { FilterAccordionItem };
