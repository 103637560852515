import { LoginCallback } from '@okta/okta-react';
import Loading from 'components/Loading';
import ChatPage from 'pages/ChatPage';
import SearchPage from 'pages/SearchPage';
import { Route, Routes } from 'react-router-dom';

import RequiredAuth from './SecureRoute';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route
        path='login/callback'
        element={
          <LoginCallback
            loadingElement={
              <div className='d-flex justify-content-center align-items-center h-100'>
                <Loading />
              </div>
            }
            errorComponent={({ error }) => (
              <div>
                Unexpected error while authenticating... Please try again later.{' '}
                {error.message}
              </div>
            )}
          />
        }
      />

      {/* Private Routes */}
      <Route path='/' element={<RequiredAuth />}>
        <Route path='' element={<SearchPage />} />
        <Route path='chat' element={<ChatPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
