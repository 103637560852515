import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { sendChatMessage } from './api';
import { ChatMessage, ChatState } from './typings';

const initialState: ChatState = {
  messages: [],
  status: 'idle',
  error: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    updateChatStreamData: (state, action) => {
      const chatMessage: ChatMessage = action.payload;
      state.messages.push(chatMessage);
    },
    popLastChatMessage: (state) => {
      state.messages.pop();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendChatMessage.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(sendChatMessage.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(sendChatMessage.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as Error;
    });
  },
});

export const { updateChatStreamData, popLastChatMessage } = chatSlice.actions;

// selectors
export const selectChatMessages = (state: RootState) => state.chat.messages;

export const selectChatStatus = (state: RootState) => state.chat.status;

export const selectChatError = (state: RootState) => state.chat.error;

export default chatSlice.reducer;
