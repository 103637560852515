import styled from "styled-components";

export const SidebarDrawerContainer = styled.div<{
  $open: boolean;
}>`
  z-index: 9999;
  background-color: #dbeafe;
  transition: 0.5s;
  left: ${(props) => (props?.$open ? "0" : "-260px")};
  width: 260px;
`;

export const SidebarDrawerOverlay = styled.div<{
  $open: boolean;
}>`
  z-index: 9998;
  transition: 0.5s;

  top: ${(props) => (props?.$open ? "0" : "inherit")};
  left: ${(props) => (props?.$open ? "0" : "inherit")};
  right: ${(props) => (props?.$open ? "0" : "inherit")};
  bottom: ${(props) => (props?.$open ? "0" : "inherit")};
  opacity: ${(props) => (props?.$open ? "0.6" : "0")};
`;
