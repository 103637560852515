import ChatBody from 'components/ChatBody';
import ChatPageContainer from 'components/ChatPageContainer';
import ChatSidebar from 'components/ChatSideBar';
import DefaultLayout from 'components/Layout/DefaultLayout';

const ChatPage: React.FC = () => {
  return (
    <div data-testid='chat-page'>
      <DefaultLayout>
        <ChatPageContainer>
          <ChatSidebar />
          <ChatBody />
        </ChatPageContainer>
      </DefaultLayout>
    </div>
  );
};

export default ChatPage;
