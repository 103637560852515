import styled from 'styled-components';

export const SearchSummaryCardContainer = styled.div`
  border: 1px solid ${(props) => props?.theme?.color?.purple200};
  background-color: ${(props) => props?.theme?.color?.purple100};
  margin-right: 20px;

  .accordion-title-bar {
    height: 55px;
    cursor: pointer;
  }

  .details {
    padding: 10px var(--sizes-size-12, 12px);
    gap: var(--sizes-size-12, 12px);
    border-radius: 6px;
    background: #f3f1ff;
    color: ${(props) => props?.theme?.color?.purple400};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.28px;
    > div {
      cursor: pointer;
    }
  }

  .accordion-content {
    padding: 0 35px 20px;

    ul.list-item-container {
      padding: 0 25px;

      > li {
        padding: 10px 0;
        border-bottom: 1px solid ${(props) => props?.theme?.color?.gray300};
        font-size: 14px;
      }
    }
  }

  .label {
    color: ${(props) => props?.theme?.color?.purple400};
    font-size: 15px;
  }
`;
