import classNames from 'classnames';
import Icon, { IconSize } from 'components/Icon';

import styles from './index.module.scss';

const ChatSidebar: React.FC = () => {
  return (
    <div
      className={classNames(
        'd-flex flex-column overflow-auto border-end border-gray d-none d-sm-flex',
        styles.sidebarContainer,
      )}
    >
      <div className='p-3 border-bottom border-gray'>
        <button
          type='button'
          className='btn btn-outline-primary d-flex align-items-center gap-2 w-100 justify-content-center'
        >
          <Icon name='add_circle' size={IconSize.md} />
          New Chat
        </button>
      </div>

      <div className='flex-grow-1 overflow-auto'>
        <div className='mb-4'>
          <div className='border-bottom border-gray px-3 py-2 fw-medium text-secondary'>
            Today
          </div>
          <div className='d-flex align-items-center gap-3 px-3 py-2 border-bottom border-gray'>
            <span className='d-flex justify-content-center align-items-center rounded-2 px-1 bg-secondary bg-opacity-10'>
              <Icon name='message_icon' size={IconSize.sm} />
            </span>
            <div>Business Expansion</div>
          </div>
          <div className='d-flex align-items-center gap-3 px-3 py-2 border-bottom border-gray'>
            <span className='d-flex justify-content-center align-items-center rounded-2 px-1 bg-secondary bg-opacity-10'>
              <Icon name='message_icon' size={IconSize.sm} />
            </span>
            <div>Business Expansion</div>
          </div>
        </div>

        <div className='mb-4'>
          <div className='border-bottom border-gray px-3 py-2 fw-medium text-secondary'>
            Yesterday
          </div>
          <div className='d-flex align-items-center gap-3 px-3 py-2 border-bottom border-gray'>
            <span className='d-flex justify-content-center align-items-center rounded-2 px-1 bg-secondary bg-opacity-10'>
              <Icon name='message_icon' size={IconSize.sm} />
            </span>
            <div>Business Expansion</div>
          </div>
          <div className='d-flex align-items-center gap-3 px-3 py-2 border-bottom border-gray'>
            <span className='d-flex justify-content-center align-items-center rounded-2 px-1 bg-secondary bg-opacity-10'>
              <Icon name='message_icon' size={IconSize.sm} />
            </span>
            <div>Business Expansion</div>
          </div>
        </div>

        <div className='mb-4'>
          <div className='border-bottom border-gray px-3 py-2 fw-medium text-secondary'>
            Previous 7 days
          </div>
          <div className='d-flex align-items-center gap-3 px-3 py-2 border-bottom border-gray'>
            <span className='d-flex justify-content-center align-items-center rounded-2 px-1 bg-secondary bg-opacity-10'>
              <Icon name='message_icon' size={IconSize.sm} />
            </span>
            <div>Business Expansion</div>
          </div>
          <div className='d-flex align-items-center gap-3 px-3 py-2 border-bottom border-gray'>
            <span className='d-flex justify-content-center align-items-center rounded-2 px-1 bg-secondary bg-opacity-10'>
              <Icon name='message_icon' size={IconSize.sm} />
            </span>
            <div>Business Expansion</div>
          </div>
        </div>

        <div className='mb-4'>
          <div className='border-bottom border-gray px-3 py-2 fw-medium text-secondary'>
            Previous 30 days
          </div>
          <div className='d-flex align-items-center gap-3 px-3 py-2 border-bottom border-gray'>
            <span className='d-flex justify-content-center align-items-center rounded-2 px-1 bg-secondary bg-opacity-10'>
              <Icon name='message_icon' size={IconSize.sm} />
            </span>
            <div>Business Expansion</div>
          </div>
          <div className='d-flex align-items-center gap-3 px-3 py-2 border-bottom border-gray'>
            <span className='d-flex justify-content-center align-items-center rounded-2 px-1 bg-secondary bg-opacity-10'>
              <Icon name='message_icon' size={IconSize.sm} />
            </span>
            <div>Business Expansion</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSidebar;
