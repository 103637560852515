import Loading from 'components/Loading';

const SearchResultsLoading: React.FC = () => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center h-100'>
      <Loading className='h-25' />
      <h4 className='mt-3'>Searching...</h4>
    </div>
  );
};

export default SearchResultsLoading;
