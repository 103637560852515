import * as Styled from './RealatedRequestedThemes.styles';

const RelatedRequestThemes: React.FC = () => {
  return (
    <div className='row pt-3 pb-3'>
      <div className='col-12 col-lg-3'>
        <Styled.SectionTitle className='fw-500 mt-1 mb-2 mb-lg-0'>
          Request Related Themes
        </Styled.SectionTitle>
      </div>

      <div className='col-12 col-lg-9'>
        <div className='flex-grow-1 d-flex flex-wrap gap-3'>
          <Styled.ChipItem>LNG</Styled.ChipItem>
          <Styled.ChipItem>Floating Storage Units</Styled.ChipItem>
          <Styled.ChipItem>Carbon Capture</Styled.ChipItem>
          <Styled.ChipItem>Carbon Storage</Styled.ChipItem>
          <Styled.ChipItem>Clean Energy</Styled.ChipItem>
          <Styled.ChipItem>
            Floating Energy Storage & offloading
          </Styled.ChipItem>
        </div>
      </div>
    </div>
  );
};

export default RelatedRequestThemes;
