import Icon from 'components/Icon';
import React from 'react';

import * as Styled from './Sidebar.styles';

interface MenuIconButtonProps {
  isExpanded: boolean;
  onClick(): void;
}

const MenuIconButton: React.FC<MenuIconButtonProps> = ({
  isExpanded,
  onClick,
}) => {
  return (
    <Styled.MenuIconButtonWrapper
      className='d-flex align-items-center rounded-circle gap-3 justify-content-center shadow-sm bg-white'
      onClick={onClick}
    >
      <Icon name={isExpanded ? 'close' : 'menu'} />
    </Styled.MenuIconButtonWrapper>
  );
};

export default MenuIconButton;
