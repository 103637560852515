import 'assets/fonts/Inter-Regular.woff2';
import { createGlobalStyle } from 'styled-components';
import { ThemeOptions } from 'theme';

const GlobalStyles = createGlobalStyle<{ $currentTheme: ThemeOptions }>`
  body {
    font-family: "Inter", "ui-sans-serif", "system-ui", "-apple-system", "system-ui", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
`;

export default GlobalStyles;
