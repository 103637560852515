import Icon, { IconSize } from 'components/Icon';
import {
  CustomIconNames,
  DynamicComponentNames,
} from 'components/WorkspaceConfigurations/typings';
import {
  DynamicWrapperProps,
  getFileExtensionIconName,
  getRenderParamValueFromDataByKey,
} from 'utils/workspace';

export interface IconDynamicWrapperProps extends DynamicWrapperProps {
  componentName: DynamicComponentNames;
}

const IconDynamicWrapper: React.FC<IconDynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
  componentName,
}) => {
  const field = getRenderParamValueFromDataByKey(
    requiredRenderParams,
    'field',
    retrievalMaterialData,
  );

  if (componentName === DynamicComponentNames.AttachmentIcon && !field) {
    return null;
  }

  const getIcon = () => {
    let iconName: CustomIconNames | string = '';
    let iconSize: number = 0;

    switch (componentName) {
      case DynamicComponentNames.DocumentIcon:
        iconName = field ? getFileExtensionIconName(field) : iconName;
        iconSize = IconSize.md;
        break;
      case DynamicComponentNames.AttachmentIcon:
        iconName = CustomIconNames.AttachFile;
        iconSize = IconSize.md;
        break;
      case DynamicComponentNames.DownloadIcon:
        iconName = CustomIconNames.Download;
        iconSize = IconSize.md;
        break;
      case DynamicComponentNames.EyeIcon:
        iconName = CustomIconNames.Eye;
        iconSize = IconSize.lg;
        break;
      case DynamicComponentNames.CloseIcon:
        iconName = CustomIconNames.Close;
        iconSize = IconSize.lg;
        break;
      case DynamicComponentNames.IllustrationIcon:
        iconName = CustomIconNames.Illustartion;
        iconSize = 186;
        break;
      default:
        iconName = '';
        iconSize = 0;
    }

    return { name: iconName, size: iconSize };
  };

  return <Icon name={getIcon().name} size={getIcon().size} />;
};

export default IconDynamicWrapper;
