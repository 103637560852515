export interface ChatState {
  messages: ChatMessage[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: Error | null;
}

export interface ChatMessage {
  content: string;
  role: ChatUserRoles;
}

export enum ChatUserRoles {
  User = 'user',
  Assistant = 'assistant',
}

export interface SystemMessageData {
  system_message: 'usage' | 'DONE' | 'START INNER CHAT' | 'START GENERATIVE';
  usage?: UsageData;
}

interface PromptFilterResults {
  prompt_index: number;
  content_filter_results: {
    hate: {
      filtered: boolean;
      severity: string;
    };
    self_harm: {
      filtered: boolean;
      severity: string;
    };
    sexual: {
      filtered: boolean;
      severity: string;
    };
    violence: {
      filtered: boolean;
      severity: string;
    };
  };
}

export interface FunctionCallChoice {
  delta: {
    function_call: {
      arguments: string;
    };
  };
}

export interface FinishReasonChoice {
  finish_reason: string;
  delta: any;
}

export interface RoleChoice {
  delta: {
    role: string;
  };
}

export interface ContentChoice {
  delta: {
    content: string;
  };
}

export type Choice =
  | FunctionCallChoice
  | FinishReasonChoice
  | RoleChoice
  | ContentChoice;

export interface UserMessageData {
  user_message: string;
}

export interface ChatCompletionData {
  id: string;
  prompt_filter_results?: PromptFilterResults[];
  choices: Choice[];
  chunk_instance: number;
}

export interface UsageData {
  model: string;
  completion_tokens: number;
  prompt_tokens: number;
  total_tokens: number;
}

export interface ChatMessageResponseData {
  data: SystemMessageData | ChatCompletionData | UserMessageData;
}

export interface ChatMessageRequestData {
  messages: ChatMessage[];
  gen_options: {
    max_tokens: number;
    stream: boolean;
  };
  request_id: string;
  consumer_id: 'KN';
  engine: 'gpt-4';
  context: string;
}
