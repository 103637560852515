import styled from 'styled-components';

export const IconWrapper = styled.span`
  .icon {
    color: ${(props) => props?.theme?.color?.primary};

    &.sm {
      width: 16px;
      height: 16px;
    }
    &.md {
      width: 24px;
      height: 24px;
    }
    &.lg {
      width: 35px;
      height: 35px;
    }
  }
`;
