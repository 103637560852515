import styled from 'styled-components';

export const SidebarContainer = styled.div<{ $open: boolean }>`
  background-color: ${(props) => props?.theme?.color?.gray200};
  transition: 0.2s;
  width: ${(props) => (props?.$open ? '260px' : '70px')};
`;

export const SidebarMenuListContainer = styled.div<{ $expanded: boolean }>`
  width: ${(props) => (props?.$expanded ? '100%' : 'auto')};
`;

export const MenuIconButtonWrapper = styled.div`
  transition: 0.3s;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props?.theme?.color?.purple200};
  }
`;

export const MenuItemWrapper = styled.div<{
  $expanded: boolean;
  $active: boolean;
}>`
  border-radius: ${(props) => (props?.$expanded ? '100px' : '50%')};
  height: 40px;
  transition: background 0.3s;
  cursor: pointer;

  width: ${(props) => (props?.$expanded ? '100%' : '40px')};
  padding: ${(props) => (props?.$expanded ? '15px' : 'auto')};
  justify-content: ${(props) => (!props?.$expanded ? 'center' : 'auto')};
  background: ${(props) =>
    props?.$active
      ? props?.theme?.color?.purple400
      : props?.theme?.color?.purple200};

  &:hover {
    background-color: ${(props) => props?.theme?.color?.purple300};

    * {
      color: white;
    }
  }
`;
