import Form from 'react-bootstrap/Form';

import styles from './index.module.scss';

interface CheckBoxProps {
  label: string;
  type: 'checkbox' | 'radio' | 'switch';
  isChecked: boolean;
  disabled: boolean;
  onChange: () => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  type,
  isChecked,
  disabled,
  onChange,
}) => {
  return (
    <div className='d-flex align-items-center justify-content-between w-100 mb-1 bg-transparent'>
      <div className={disabled ? styles.disabled : ''}>{label}</div>
      <Form.Check
        disabled={disabled}
        type={type}
        checked={isChecked}
        onChange={onChange}
      />
    </div>
  );
};

export default CheckBox;
