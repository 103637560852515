import styled from 'styled-components';

export const SearchBarContainer = styled.div`
  .banner-image {
    object-fit: cover;
    width: 100%;
    height: 100px;
  }

  & .search-bar-container {
    height: 75px;
    left: 50px;
    right: 50px;
    bottom: -40px;
    border: 1px solid ${(props) => props?.theme?.color?.primary};

    @media (max-width: 1024px) {
      left: 20px;
      right: 20px;
    }
  }
`;

export const ContentContainer = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 50px;
  padding-right: 0px;

  .Preview-layout {
    border-radius: 0px 0px var(--sizes-size-12, 12px) 0px;
    border-top: 1px solid var(--neutral-03-light, #e3e9ee);
    border-left: 1px solid var(--neutral-03-light, #e3e9ee);
    background: var(--neutral-01-light, #fcfdfe);
    height: 100%;
    > div {
      padding-top: 7px;

      padding-right: 15px;
    }
  }

  .ResultCard-layout {
    transition: background-color 0.3s ease;
  }

  .ResultCard-layout:hover {
    background-color: ${(props) => props?.theme?.color?.purple100};
  }

  @media (max-width: 1024px) {
    padding: 0 20px;
  }

  .content-wrapper {
    .filter-panel-column {
      border: none;
      padding-right: 16px;
      border-right: 2px solid ${(props) => props?.theme?.color?.gray200};
    }
  }
`;
