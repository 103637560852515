import React from 'react';

import * as Styled from './Icon.styles';

export enum IconSize {
  sm = 16,
  md = 24,
  lg = 35,
}

export interface IconProps {
  size?: IconSize;
  name: string;
  className?: string;
  onClick?: () => void;
}

const Icon: React.FC<IconProps> = ({ name, size = 16, className, onClick }) => {
  return (
    <Styled.IconWrapper className={`icon ${size}`}>
      <img
        src={require(`assets/icons/${name}.svg`)}
        alt={name}
        className={className}
        width={`${size}px`}
        height={`${size}px`}
        onClick={onClick}
      />
    </Styled.IconWrapper>
  );
};

export default Icon;
