import Icon, { IconSize } from 'components/Icon';
import React from 'react';

import './styles.scss';

type fieldsObject = {
  text: string;
  url: string;
};

export interface TextLinkProps {
  fieldsArray: fieldsObject[];
}

const TextLink: React.FC<TextLinkProps> = ({ fieldsArray }) => {
  return (
    <div className='d-flex flex-column'>
      {fieldsArray.map((field) => (
        <div
          key={field.text}
          className='TextLink'
          onClick={() => field.url && window.open(field.url, '_blank')}
        >
          <Icon name='link' size={IconSize.sm} />
          {field.text}
        </div>
      ))}
    </div>
  );
};

export default TextLink;
