import classNames from 'classnames';
import Icon, { IconSize } from 'components/Icon';
import { useState } from 'react';
import { sendChatMessage } from 'redux/Chat/api';
import { selectChatStatus } from 'redux/Chat/slice';
import { ChatUserRoles } from 'redux/Chat/typings';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import styles from './index.module.scss';

const InputBox: React.FC = () => {
  const dispatch = useAppDispatch();
  const chatStatus = useAppSelector(selectChatStatus);

  const [chatMessage, setChatMessage] = useState<string>('');

  const handleSendMessage = () => {
    dispatch(
      sendChatMessage({
        role: ChatUserRoles.User,
        content: chatMessage,
      }),
    );

    setChatMessage('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <div
      className={classNames(
        'border-top border-gray',
        styles.messageFormContainer,
      )}
    >
      <div className='d-flex flex-row gap-3'>
        <div
          className={classNames(
            'd-flex flex-row flex-grow-1 border border-primary rounded-3 align-items-center px-3',
            styles.messageInputWrapper,
          )}
        >
          <input
            type='text'
            placeholder={
              chatStatus === 'loading' ? 'Loading...' : 'Type a message'
            }
            disabled={chatStatus === 'loading'}
            className='w-100 border-0 h-100 rounded-3'
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <span>
            <Icon name='add_circle' size={IconSize.md} />
          </span>
        </div>
        <div>
          <button
            type='button'
            className={classNames(
              'btn btn-primary rounded-5 p-1',
              styles.messageSendButton,
            )}
            onClick={handleSendMessage}
            disabled={chatMessage.length === 0 || chatStatus === 'loading'}
          >
            <Icon name='paperplane_icon' size={IconSize.md} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputBox;
