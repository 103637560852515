import OktaAuth from '@okta/okta-auth-js';

const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.REACT_APP_OKTA_TESTING_DISABLE_HTTPS_CHECK;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

const oktaConfigurationSettings = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['profile', 'email', 'groups', 'openid'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};

const oktaAuth = new OktaAuth(oktaConfigurationSettings.oidc);

export default oktaAuth;
