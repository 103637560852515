import { selectIsSideBarOpen, toggleSidebar } from 'redux/App/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import * as Styled from './Sidebar.styles';
import SidebarContent from './SidebarContent';

const Sidebar: React.FC = () => {
  const isSideBarOpen = useAppSelector(selectIsSideBarOpen);
  const dispatch = useAppDispatch();

  const handleClickMenuIcon = () => {
    dispatch(toggleSidebar());
  };

  return (
    <Styled.SidebarContainer
      $open={isSideBarOpen}
      className='d-none d-md-flex flex-column align-items-center flex-grow-1 px-2 py-4 transition-200'
    >
      <SidebarContent
        onClickMenuIcon={handleClickMenuIcon}
        isExpanded={isSideBarOpen}
      />
    </Styled.SidebarContainer>
  );
};

export default Sidebar;
