import Divider from 'components/Divider';
import {
  ComponentNameToPropsMap,
  DynamicComponentNames,
  Origin,
  ParamName,
  RequiredRenderParam,
  ResultLayout,
  WorkspaceConfiguration,
} from 'components/WorkspaceConfigurations/typings';
import ButtonDynamicWrapper from 'dynamic/ButtonDynamicWrapper';
import CenteredImageWithTextDynamicWrapper from 'dynamic/CenteredImageWithTextDynamicWrapper';
import HtmlContentDynamicWapper from 'dynamic/HtmlContentDynamicWrapper';
import IconDynamicWrapper from 'dynamic/IconDynamicWrapper';
import LabelOnTopDynamicWrapper from 'dynamic/LabelOnTopDynamicWrapper';
import PillDynamicWrapper from 'dynamic/PillDynamicWrapper';
import SectionHeaderDynamicWrapper from 'dynamic/SectionHeaderDynamicWrapper';
import SummaryDynamicWrapper from 'dynamic/SummaryDynamicWrapper';
import TextAsLinkDynamicWrapper from 'dynamic/TextAsLinkDynamicWrapper';
import TitleAsLinkDynamicWrapper from 'dynamic/TitleAsLinkDynamicWrapper';
import TitleDynamicWrapper from 'dynamic/TitleDynamicWrapper';
import moment from 'moment';
import { RetrievalMaterial } from 'redux/Retrieval/typings';

type FileExtensionToIconNameMap = {
  [key: string]: string;
};

export const fileExtensionToIconNameMap: FileExtensionToIconNameMap = {
  ppt: 'powerpointIcon',
  xls: 'excelIcon',
  csv: 'excelIcon',
  zip: 'zipIcon',
  pdf: 'adobeIcon',
  doc: 'wordIcon',
};

/**
 * This utility function helps determine what should be used as the value for a component property.
 * For example, if the component property is "title", this function will determine what value should be used for the title,
 * whether that is a 'static' value (meaning we simply use the value provided) or a 'dynamic' value (meaning we look up the value
 * in the retrieval material data.
 *
 * @param requiredRenderParams The parameters required to render the component
 * @param paramName The name of the component property to get the value for (e.g. title, url, target)
 * @param valueOrLookupKey Either the static value to use for the component property, or the key to use to look up the value in the retrieval material data
 * @param retrievalMaterialData The retrieval material data used for the value of the component property
 * @returns string value to use for the component property
 */
export const getRenderParamValueFromDataByKey = (
  requiredRenderParams: RequiredRenderParam[],
  paramName: ParamName,
  retrievalMaterialData: RetrievalMaterial,
): string | undefined => {
  const renderParam = requiredRenderParams.find(
    (p: RequiredRenderParam) => p.paramName === paramName,
  );

  if (!renderParam) {
    console.error(
      `Could not find paramName (${paramName}) in requiredRenderParams ${JSON.stringify(
        requiredRenderParams,
        null,
        2,
      )}`,
    );
    return '';
  }

  if (renderParam.paramType === 'static') {
    return renderParam.paramStaticValue;
  }

  // if renderParam.paramType === 'dynamic'
  const value =
    retrievalMaterialData[
      renderParam.paramDynamicLookupKey as keyof RetrievalMaterial
    ];

  if (value === undefined) {
    return value;
  }

  return typeof value === 'object' ? JSON.stringify(value) : String(value);
};

export const getFileExtensionIconName = (fileName: string): string => {
  let iconName = 'miscIcon'; // default to miscIcon

  // abort early if no extension
  const fileNameDoesNotHaveExtension = fileName.indexOf('.') <= -1;
  if (fileNameDoesNotHaveExtension) {
    return iconName;
  }

  // get the extension
  const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);

  iconName = fileExtensionToIconNameMap[fileExtension];

  return iconName;
};

export const formatDate = (timestamp: string, format: string): string => {
  return moment.unix(Number(timestamp)).format(format);
};

export const getDynamicComponentByName = (
  retrievalMaterialData: RetrievalMaterial,
  requiredRenderParams: RequiredRenderParam[],
  componentName: DynamicComponentNames,
  origin: Origin,
  callbacks?: CallbackProps,
) => {
  switch (componentName) {
    case DynamicComponentNames.TitleAsLink:
      return (
        <TitleAsLinkDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
          origin={origin}
        />
      );
    case DynamicComponentNames.LabelOnTop:
      return (
        <LabelOnTopDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.Summary:
      return (
        <SummaryDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.HtmlContent:
      return (
        <HtmlContentDynamicWapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.Pill:
      return (
        <PillDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.SectionHeader:
      return (
        <SectionHeaderDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.TextAslink:
      return (
        <TextAsLinkDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.CenteredImageWithText:
      return (
        <CenteredImageWithTextDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.Button:
      return (
        <ButtonDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
          callbackProps={callbacks}
        />
      );
    case DynamicComponentNames.Title:
      return (
        <TitleDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
        />
      );
    case DynamicComponentNames.Divider:
      return <Divider />;

    case DynamicComponentNames.AttachmentIcon:
    case DynamicComponentNames.DocumentIcon:
    case DynamicComponentNames.DownloadIcon:
    case DynamicComponentNames.EyeIcon:
    case DynamicComponentNames.CloseIcon:
    case DynamicComponentNames.IllustrationIcon:
      return (
        <IconDynamicWrapper
          requiredRenderParams={requiredRenderParams}
          retrievalMaterialData={retrievalMaterialData}
          componentName={componentName}
        />
      );

    default:
      return null;
  }
};

export const validateWorkspaceConfig = (
  workspaceConfig: WorkspaceConfiguration,
): void => {
  console.info(
    `Starting workspace configuration validation for ${workspaceConfig.workspaceName}`,
  );
  // resultCardLayout validation
  const resultCardLayout = workspaceConfig.search.resultCardLayout;

  for (const row of resultCardLayout.rows) {
    if (!row.id) {
      console.warn(
        `Workspace Validation: ID missing for row in resultCardLayout, this could cause issues with the layout. Check the workspace configuration for ${workspaceConfig.workspaceName}`,
      );
    }

    const columns = row.columns;

    for (const column of columns) {
      if (!column.id) {
        console.warn(
          `Workspace Validation: ID missing for column in resultCardLayout, this could cause issues with the layout. Check the workspace configuration for ${workspaceConfig.workspaceName}`,
        );
      }

      if (!column.size) {
        console.warn(
          `Workspace Validation: Size missing for column in resultCardLayout, this could cause issues with the layout. Check the workspace configuration for ${workspaceConfig.workspaceName}`,
        );
      }

      if (!column.componentName) {
        console.warn(
          `Workspace Validation: componentName missing for column in resultCardLayout, this could cause issues with the layout. Check the workspace configuration for ${workspaceConfig.workspaceName}`,
        );
      }

      if (!column.requiredComponentRenderParams) {
        console.warn(
          `Workspace Validation: requiredComponentRenderParams missing for column in resultCardLayout, this could cause issues with the layout. Check the workspace configuration for ${workspaceConfig.workspaceName}`,
        );
      }

      // validate required component render params contains
      // the values needed based on the props of the components
      // should be updated to include all dynamic components.
      // TODO: We may want to make this recursive, since
      // a row can have any n number of columns, and a column
      // can have any n number of rows.
      const componentName = column.componentName;
      const requiredParams = column.requiredComponentRenderParams;

      const componentPropsMap: ComponentNameToPropsMap = {
        [DynamicComponentNames.TitleAsLink]: ['title', 'url', 'target'],
        [DynamicComponentNames.LabelOnTop]: ['label', 'field', 'icon'],
        [DynamicComponentNames.Summary]: ['description'],
        [DynamicComponentNames.AttachmentIcon]: ['size', 'name'],
        [DynamicComponentNames.DocumentIcon]: ['size', 'name'],
        [DynamicComponentNames.DownloadIcon]: ['size', 'name'],
        [DynamicComponentNames.EyeIcon]: ['size', 'name'],
        [DynamicComponentNames.CloseIcon]: ['size', 'name'],
        [DynamicComponentNames.IllustrationIcon]: ['size', 'name'],
        [DynamicComponentNames.HtmlContent]: ['content'],
        [DynamicComponentNames.Pill]: ['field', 'url', 'showCopyIcon'],
        [DynamicComponentNames.SectionHeader]: ['header', 'icon'],
        [DynamicComponentNames.TextAslink]: ['fieldsArray'],
        [DynamicComponentNames.CenteredImageWithText]: [
          'icon',
          'text1',
          'text2',
        ],
        [DynamicComponentNames.Divider]: () => [],
        [DynamicComponentNames.Title]: ['title'],
        [DynamicComponentNames.Button]: ['ariaLabel', 'className'],
      };

      const propsNeededByCurrentComponent = componentPropsMap[
        componentName
      ] as [];
      const paramNamesProvidedInWorkspaceConfig = requiredParams.map(
        (p: RequiredRenderParam) => p.paramName,
      );

      const paramNamesProvidedInWorkspaceConfigSet = new Set(
        paramNamesProvidedInWorkspaceConfig,
      );

      const hasRequiredParams = propsNeededByCurrentComponent.every(
        (p: ParamName) => {
          return paramNamesProvidedInWorkspaceConfigSet.has(p);
        },
      );

      if (!hasRequiredParams) {
        console.warn(
          `Workspace Validation: ${componentName} components requires ${JSON.stringify(
            propsNeededByCurrentComponent,
          )} in the requiredComponentRenderParams but only received ${JSON.stringify(
            paramNamesProvidedInWorkspaceConfig,
          )} in the workspace configuration object. Check the workspace configuration for ${
            workspaceConfig.workspaceName
          }`,
        );
      }
    }
  }

  console.info(
    `Workspace configuration validation for ${workspaceConfig.workspaceName} complete. If issues were found, they were logged to the console as warnings.`,
  );
};

type CallbackProps = {
  [key: string]: Function;
};

export const generateResultLayoutComponent = (
  resultLayout: ResultLayout,
  retrievalMaterial: RetrievalMaterial,
  origin: Origin,
  callbacks?: CallbackProps,
): JSX.Element => {
  return (
    <div className='container'>
      {resultLayout.rows.map((row) => (
        <div key={row.id} className='row m-3 align-items-center'>
          {row.columns.map((column) => (
            <div key={column.id} className={`col-${column.size}`}>
              {getDynamicComponentByName(
                retrievalMaterial,
                column.requiredComponentRenderParams,
                column.componentName,
                origin,
                callbacks,
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export interface DynamicWrapperProps {
  requiredRenderParams: RequiredRenderParam[];
  retrievalMaterialData: RetrievalMaterial;
  callbackProps?: CallbackProps;
}

export const getRenderProps = (
  requiredRenderParams: RequiredRenderParam[],
  retrievalMaterialData: RetrievalMaterial,
) => {
  const paramNames = requiredRenderParams.map((param) => param.paramName);
  return Object.fromEntries(
    paramNames.map((paramName) => [
      paramName,
      getRenderParamValueFromDataByKey(
        requiredRenderParams,
        paramName,
        retrievalMaterialData,
      ),
    ]),
  );
};
