import DefaultAvatar from 'assets/images/default_avatar.png';
import classNames from 'classnames';
import Icon, { IconSize } from 'components/Icon';
import htmlReactParser from 'html-react-parser';
import React from 'react';

import styles from './index.module.scss';

interface MessageItemProps {
  assistant: boolean;
  message: string;
}

const MessageItem: React.FC<MessageItemProps> = ({ assistant, message }) => {
  const handleCopyAssistantText = () => {
    console.log('Copied assistant text to clipboard');
    // TODO - Add animation when text is copied
    navigator.clipboard.writeText(message);
  };

  const handleThumbsUp = () => {
    console.log('Thumbs up not yet implemented...');
    // TODO - handle when thumbs up is clicked
  };

  const handleThumbsDown = () => {
    console.log('Thumbs down not yet implemented...');
    // TODO - handle when thumbs down is clicked
  };

  return (
    <div
      className={classNames('d-flex gap-4', styles.messageBubbleWrapper, {
        [styles.assistantMessageBackground]: assistant,
        [styles.userMessageBackground]: !assistant,
      })}
    >
      <div className='align-self-start'>
        {assistant && <Icon name='bot' size={IconSize.md} />}
        {!assistant && (
          <img
            src={DefaultAvatar}
            alt='Chat users avatar'
            className={classNames(styles.messageSenderImage, 'rounded-circle')}
          />
        )}
      </div>
      <div className='flex-grow-1 d-flex flex-column flex-sm-column flex-lg-row'>
        <div className='flex-grow-1'>{htmlReactParser(message)}</div>

        <div className='d-flex flex-row gap-3 justify-content-end'>
          {assistant && (
            <>
              <span
                className={styles.messageActionButton}
                onClick={handleCopyAssistantText}
              >
                <Icon name='clipboard_icon' size={IconSize.md} />
              </span>
              <span
                className={styles.messageActionButton}
                onClick={handleThumbsUp}
              >
                <Icon name='thumbs_up' size={IconSize.md} />
              </span>
              <span
                className={styles.messageActionButton}
                onClick={handleThumbsDown}
              >
                <Icon name='thumbs_down' size={IconSize.md} />
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
