import HeroBG from 'assets/images/hero_bg.jpg';
import Icon, { IconSize } from 'components/Icon';
import { useTypedSearchParams } from 'hooks/useSearchParams';
import React, { useEffect } from 'react';
import { getWorkspaceConfig } from 'redux/App/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { conductRetrievalSearch } from 'redux/Retrieval/api';

import * as Styled from './SearchPageContent.styles';

const SearchBar: React.FC = () => {
  const workspace = useAppSelector(getWorkspaceConfig);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useTypedSearchParams();

  // conduct search on first page
  // load if search term is present
  useEffect(() => {
    if (searchParams.q) {
      dispatch(
        conductRetrievalSearch([searchParams, workspace.search.filters]),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchParams({ q: event.target.value });
  };

  const handleSubmitSearchTerm = () => {
    dispatch(conductRetrievalSearch([searchParams, workspace.search.filters]));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmitSearchTerm();
    }
  };

  return (
    <Styled.SearchBarContainer className='position-relative mb-5'>
      <img src={HeroBG} className='banner-image' alt='Hero Banner' />

      <div className='position-absolute px-4 py-2 bg-white shadow rounded-3 d-flex flex-row align-items-center gap-4 search-bar-container'>
        <Icon size={IconSize.lg} name='bot' />
        <div className='flex-grow-1'>
          <input
            className='w-100 form-control form-control-lg border-0 p-1 shadow-none'
            type='text'
            placeholder='Search'
            aria-label='Search'
            value={searchParams.q}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div>
          <button
            onClick={handleSubmitSearchTerm}
            className='btn btn-primary text-white rounded border-0 px-3 py-2'
            disabled={!searchParams.q}
          >
            Search
          </button>
        </div>
      </div>
    </Styled.SearchBarContainer>
  );
};

export default SearchBar;
