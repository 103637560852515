export interface GenerativeState {
  messages: GenerativeMessage[];
  lastReceivedUserQuery: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: Error | null;
}

export interface GenerativeMessage {
  content: string;
  role: GenerativeUserRoles;
}

export enum GenerativeUserRoles {
  User = 'user',
  Assistant = 'assistant',
  System = 'system',
}

export interface SystemMessageData {
  system_message: 'usage' | 'DONE' | 'START INNER CHAT' | 'START GENERATIVE';
  usage?: UsageData;
}

interface PromptFilterResults {
  prompt_index: number;
  content_filter_results: {
    hate: {
      filtered: boolean;
      severity: string;
    };
    self_harm: {
      filtered: boolean;
      severity: string;
    };
    sexual: {
      filtered: boolean;
      severity: string;
    };
    violence: {
      filtered: boolean;
      severity: string;
    };
  };
}

export interface FunctionCallChoice {
  delta: {
    function_call: {
      arguments: string;
    };
  };
}

export interface FinishReasonChoice {
  finish_reason: string;
  delta: any;
}

export interface RoleChoice {
  delta: {
    role: string;
  };
}

export interface ContentChoice {
  delta: {
    content: string;
  };
}

export type Choice =
  | FunctionCallChoice
  | FinishReasonChoice
  | RoleChoice
  | ContentChoice;

export interface UserMessageData {
  user_message: string;
}

export interface GenerativeCompletionData {
  id: string;
  prompt_filter_results?: PromptFilterResults[];
  choices: Choice[];
  chunk_instance: number;
}

export interface UsageData {
  model: string;
  completion_tokens: number;
  prompt_tokens: number;
  total_tokens: number;
}

export interface GenerativeMessageResponseData {
  data: SystemMessageData | GenerativeCompletionData | UserMessageData;
}

export interface GenerativeMessageRequestData {
  messages: GenerativeMessage[];
  gen_options: {
    max_tokens: number;
    stream: boolean;
    temperature: number;
    top_p: number;
    frequency_penalty: number;
    presence_penalty: number;
    stop: string;
  };
  openai_api_options: {
    openai_api_key: string;
    openai_api_type: string;
    openai_api_version: string;
    openai_api_base: string;
  };
  request_id: string;
  consumer_id: 'KN' | 'DRS';
  engine: 'gpt-4';
  context: string;
}
