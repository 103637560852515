import { createSlice } from '@reduxjs/toolkit';
import { workspaces } from 'components/WorkspaceConfigurations';
import { WorkspaceConfiguration } from 'components/WorkspaceConfigurations/typings';
import { RootState } from 'redux/store';
import { ThemeOptions } from 'theme';
import { validateWorkspaceConfig } from 'utils/workspace';

export interface AppState {
  isSideBarOpen: boolean;
  currentTheme: ThemeOptions;
  currentWorkspaceConfig: WorkspaceConfiguration;
}

// initial state
const initialState: AppState = {
  isSideBarOpen: false,
  currentTheme: ThemeOptions.Light,
  currentWorkspaceConfig: workspaces[0],
};

// reducer + actions
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
    toggleTheme: (state) => {
      state.currentTheme =
        state.currentTheme === ThemeOptions.Light
          ? ThemeOptions.Dark
          : ThemeOptions.Light;
    },
    setCurrentWorkspace: (state, action) => {
      const selectedWorkspace = workspaces.find(
        (workspace) => workspace.id === action.payload,
      );

      if (!selectedWorkspace) {
        console.error(`Workspace with id ${action.payload} not found`);
        return;
      }

      // TODO: Move this validation to the API call
      // for fetching the workspace when that is complete.
      validateWorkspaceConfig(selectedWorkspace);

      state.currentWorkspaceConfig = selectedWorkspace;
    },
  },
});

export const { toggleSidebar, toggleTheme, setCurrentWorkspace } =
  appSlice.actions;

// selectors
export const selectIsSideBarOpen = (state: RootState) =>
  state.app.isSideBarOpen;

export const selectCurrentTheme = (state: RootState) => state.app.currentTheme;

export const getWorkspaceConfig = (state: RootState) =>
  state.app.currentWorkspaceConfig;

export default appSlice.reducer;
