import { createAsyncThunk } from '@reduxjs/toolkit';
import { SearchFilter } from 'components/WorkspaceConfigurations/typings';
import { SearchParams } from 'hooks/useSearchParams';
import oktaAuthService from 'oktaConfig';
import { v4 as uuidv4 } from 'uuid';

import {
  RetreivalFilter,
  RetrievalRequestData,
  RetrievalResponseData,
} from './typings';

export const conductRetrievalSearch = createAsyncThunk<
  RetrievalResponseData,
  [Partial<SearchParams>, SearchFilter[]]
>(
  'retrieval/conductRetrievalSearch',

  async (searchParamsAndWorkspaceFilters, { rejectWithValue }) => {
    const [retrievalSearchParams, workspaceFilters] =
      searchParamsAndWorkspaceFilters;

    const requestId = uuidv4();
    console.info(
      `Starting retrieval search for request ID: ${requestId}. Search Params: ${JSON.stringify(
        retrievalSearchParams,
      )}`,
    );

    const functionStartTime = new Date().getTime();

    const retrievalApiUrl = `${process.env.REACT_APP_RETRIEVAL_API_BASE_URL}/genai-retrieval/v1/search/`;

    const requestBody: RetrievalRequestData = {
      query: retrievalSearchParams.q || '',
      method: 'vector',
      top_n: '100',
      consumer_options: {
        // temporary hardcoding of consumer key and data source
        consumer_key: 'drs',
        data_source: 'drs',
      },
      request_id: requestId,
      filters: [],
    };

    // Add workspace filters to the retrieval request body
    if (workspaceFilters.length > 0) {
      workspaceFilters.forEach((filter) => {
        const retrievalFilter = {
          name: filter.sourceDataKey,
          type: filter.filterType,
        } as RetreivalFilter;

        requestBody.filters?.push(retrievalFilter);
      });
    }

    // Add search parameters to the retrieval request body
    if (retrievalSearchParams.filters) {
      Object.keys(retrievalSearchParams.filters).forEach((filterKey) => {
        const filterGroup = retrievalSearchParams.filters?.[filterKey];
        if (!filterGroup) {
          return;
        }

        filterGroup.forEach((filterValue) => {
          const retrievalFilter = {
            name: filterValue.name,
            type: filterValue.type,
            filter_values: filterValue.filter_values,
            predicate: filterValue.predicate,
          } as RetreivalFilter;

          requestBody.filters?.push(retrievalFilter);
        });
      });
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${oktaAuthService.getAccessToken()}`,
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY || '',
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(retrievalApiUrl, requestOptions);
    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling search API for request ID ${requestId}. Data: ${JSON.stringify(
          data,
        )}`,
      );
      return rejectWithValue(data);
    }

    const data = (await response.json()) as RetrievalResponseData;

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.info(
      `Search complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}.`,
    );

    return data;
  },
);
