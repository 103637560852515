import HtmlContent from 'components/HtmlContent';
import React from 'react';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const HtmlContentDynamicWapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.content) {
    console.error(
      `HtmlContentDynamicWapper: content is missing. content: ${props.content}`,
    );
    return null;
  }

  return <HtmlContent content={props.content} />;
};

export default HtmlContentDynamicWapper;
