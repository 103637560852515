import DefaultAvatar from 'assets/images/default_avatar.png';
import Icon, { IconSize } from 'components/Icon';
import MenuIconButton from 'components/Sidebar/MenuIconButton';
import { SidebarDrawer } from 'components/SidebarDrawer';
import { workspaces } from 'components/WorkspaceConfigurations';
import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { getWorkspaceConfig, setCurrentWorkspace } from 'redux/App/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import * as Styled from './Header.styles';

const Header: React.FC = () => {
  const workspace = useAppSelector(getWorkspaceConfig);
  const dispatch = useAppDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  const handleWorkspaceChange = (id: string) => {
    dispatch(setCurrentWorkspace(id));
  };

  return (
    <>
      <Styled.HeaderContainer className='d-flex align-items-center bg-white'>
        <div className='d-flex flex-grow-1 flex-row align-items-center gap-2'>
          <div className='d-block d-lg-none'>
            <MenuIconButton isExpanded={false} onClick={openDrawer} />
          </div>
          <Icon name='synap_logo' size={IconSize.lg} />
          <Styled.TextWrapper className='border-0 pr-5'>
            synap
          </Styled.TextWrapper>
        </div>

        <div className='d-flex justify-content-center align-items-center gap-3'>
          <Dropdown className='border rounded'>
            <Dropdown.Toggle variant='bcg-light' id='workspace-dropdown'>
              {workspace.workspaceName}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {workspaces.map((wrkspc) => (
                <Dropdown.Item
                  key={wrkspc.id}
                  id={wrkspc.id}
                  onClick={() => handleWorkspaceChange(wrkspc.id)}
                  active={wrkspc.id === workspace.id}
                >
                  {wrkspc.workspaceName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <div className='d-inline-block'>
            <img
              src={DefaultAvatar}
              width='36'
              height='36'
              alt='Avatar'
              className='rounded-circle'
            />
          </div>
        </div>
      </Styled.HeaderContainer>

      <SidebarDrawer open={drawerOpen} onClose={closeDrawer} />
    </>
  );
};

export default Header;
