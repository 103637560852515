import classNames from 'classnames';
import Icon from 'components/Icon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './Sidebar.styles';

interface MenuItemProps {
  isExpanded: boolean;
  icon: string;
  label: string | React.ReactNode;
  active: boolean;
  route: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  isExpanded,
  icon,
  label,
  active,
  route,
}) => {
  const navigate = useNavigate();

  const navPressed = () => {
    if (route) {
      navigate(route);
    }
  };

  return (
    <Styled.MenuItemWrapper
      onClick={navPressed}
      $expanded={isExpanded}
      $active={active}
      className='d-flex align-items-center gap-3'
    >
      {icon && (
        <Icon
          name={icon}
          className={classNames('w-[16px]', {
            'text-white': active,
            'text-black': isExpanded && !active,
          })}
        />
      )}
      {isExpanded && label && (
        <div
          className={classNames({
            'text-white': active,
          })}
        >
          {label}
        </div>
      )}
    </Styled.MenuItemWrapper>
  );
};

export default MenuItem;
