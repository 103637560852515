import LabelOnTop from 'components/LabelOnTop';
import {
  DynamicWrapperProps,
  formatDate,
  getRenderProps,
} from 'utils/workspace';

const LabelOnTopDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.label) {
    console.error(
      `LabelOnTopDynamicWrapper: label or field is missing. label: ${props.label}, field: ${props.field}`,
    );
    return null;
  }

  const { paramFormat, paramPostfix } =
    requiredRenderParams.find(
      (param) =>
        param.paramName === 'field' &&
        (param.paramFormat || param.paramPostfix),
    ) || {};

  if (paramFormat && props.field) {
    props.field = formatDate(props.field, paramFormat);
  }

  if (paramPostfix) {
    props.field = `${props.field} ${paramPostfix}`;
  }

  return (
    <LabelOnTop label={props.label} field={props.field} icon={props.icon} />
  );
};

export default LabelOnTopDynamicWrapper;
