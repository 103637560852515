import Icon, { IconSize } from 'components/Icon';

import * as Styled from './index.styles';

export interface LabelOnTopProps {
  label: string;
  field?: string;
  icon?: string;
}

const LabelOnTop: React.FC<LabelOnTopProps> = ({ label, field, icon }) => {
  return (
    <>
      {icon && <Icon name={icon} size={IconSize.sm} />}
      <Styled.LabelWrapper>{label}</Styled.LabelWrapper>
      <Styled.FieldWrapper>{field || '-'}</Styled.FieldWrapper>
    </>
  );
};

export default LabelOnTop;
