import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import React from 'react';

import * as Styled from './DefaultLayout.styles';

interface DefaultLayoutProps {
  children?: React.ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  return (
    <div className='d-flex flex-row vh-100'>
      <div className='d-flex'>
        <Sidebar />
      </div>

      <div className='d-flex flex-grow-1 flex-column'>
        <Header />
        <Styled.ContentWrapper className='flex-grow-1 overflow-auto'>
          {children}
        </Styled.ContentWrapper>
      </div>
    </div>
  );
};

export default DefaultLayout;
