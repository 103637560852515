import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import oktaAuthService from 'oktaConfig';
import { RetrievalMaterial } from 'redux/Retrieval/typings';
import { RootState } from 'redux/store';
import { v4 as uuidv4 } from 'uuid';

import { popLastGenerativeMessage, updateGenerativeStreamData } from './slice';
import {
  GenerativeCompletionData,
  GenerativeMessage,
  GenerativeMessageRequestData,
  GenerativeMessageResponseData,
  GenerativeUserRoles,
  SystemMessageData,
  UserMessageData,
} from './typings';

export const sendGenerativeMessage = createAsyncThunk<
  string,
  [RetrievalMaterial[], string]
>(
  'generative/sendGenerativeMessage',

  // payloadCreator's thunkAPI arg has a `signal` object
  // which can be used to cancel the request. See when
  // adding "Stop Generating" button to generative https://redux-toolkit.js.org/api/createAsyncThunk
  async (
    [retrievalMessages, userQuery],
    { rejectWithValue, dispatch, getState },
  ) => {
    const requestId = uuidv4();
    console.info(
      `Starting send generative message for request ID: ${requestId}`,
    );
    const functionStartTime = new Date().getTime();

    const generativeApiUrl = `${process.env.REACT_APP_GENERATIVE_API_BASE_URL}/genai-generative/v1/generative/`;

    const retreivalMaterialsWithBodyText = retrievalMessages.filter(
      (material) => {
        return material.body_text !== undefined;
      },
    );

    // START OF TEMPORARY FIX - this is a temporary fix because the API
    // throws an error when we pass in all of the data. We need to limit
    // the amount of data we send in as the content for the system message

    const materialsSortedByRelevancy = retreivalMaterialsWithBodyText.sort(
      (a, b) => {
        return b.relevance - a.relevance;
      },
    );

    const firstFiveMaterials = materialsSortedByRelevancy.slice(0, 5);

    // END OF TEMPORARY FIX

    const contextHeader = ['Number\tDescription\tBody Text\tAssigned To'];

    const formattedContextData = firstFiveMaterials.map((material) => {
      if (
        material.req_id === undefined ||
        material.short_desc === undefined ||
        material.body_text === undefined ||
        material.assigned_to_email === undefined
      ) {
        console.error(
          `One of req_id, short_desc, body_text, or assigned_to_email was missing when creating the context data for request ID ${requestId} in sendGenerativeMessage. Material: ${JSON.stringify(
            material,
            null,
            2,
          )}`,
        );
      }
      return `${material.req_id}\t${material.short_desc}\t${material.body_text}\t${material.assigned_to_email}`;
    });

    const context = [...contextHeader, ...formattedContextData];

    const systemMessage = `
You have access to service tickets from a Request Management System, in which the operators are research assistants that perform deep market research about questions they receive from their management consultant colleagues.
All of the rules below are equally important. Ensure you adhere to each rule while answering queries:- Base all information strictly on the data provided to you, without extrapolation or assumption. 
You are not permitted to fabricate information or access external sources.- If you are unable to complete the task, reply with
"Sorry, I was unable to complete the task. Please try again with a different query."
The context you will be provided tab separated format that includes:
- Number: the request number (in the format RES########)
- Description: the description of each request
- Body text: the email thread between the requestor and the researcher in reverse chronological order throughout the duration of the request,
- Assigned to: the researcher's nameYou will also receive from the user a search query. Your job is to provide an overview of requests that are related to this search query.It is possible that not all of the requests provided will be relevant to the user's query. Before providing a response,you must first identify the requests that are relevant to the user's search query.
You must format your response as follows:
"Key Findings:"
<Based on the user's search query, summarize the key findings from the email threads for each request, and provide a bulleted list of these short summaries. In your response, indicate why these summaries are relevant to the user's search query. Do not include the Number field.>
"Top researchers, analysts, and KT experts:"
<Follow these steps:
1. Review the Body Text for each request and identify names of people that are considered experts.
2. Include the names from the Assigned To field as well.
3. Provide a bulleted list of these experts.
4. After each name include the number of requests they are listed in, in parentheses. for example: john.smith@bcg.com (2). Do not include any other context or information.>
CONTEXT:${context}`;

    const generativeMessages: GenerativeMessage[] = [
      {
        content: systemMessage.replace(/[\n\r\t]/g, ''),
        role: GenerativeUserRoles.System,
      },
      {
        content: userQuery,
        role: GenerativeUserRoles.User,
      },
    ];

    const requestBody: GenerativeMessageRequestData = {
      messages: generativeMessages,
      gen_options: {
        stop: '',
        temperature: 0.7,
        max_tokens: 800,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        stream: true,
      },
      openai_api_options: {
        openai_api_key: 'df6ba6849b584661ad2dec8c5d2b2212',
        openai_api_type: 'azure',
        openai_api_version: '2023-07-01-preview',
        openai_api_base: 'https://bcg-kd-apim-knsearch-euro.azure-api.net',
      },
      request_id: requestId,
      consumer_id: 'DRS',
      engine: 'gpt-4',
      context: '',
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${oktaAuthService.getAccessToken()}`,
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY || '',
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(generativeApiUrl, requestOptions);
    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      console.error(
        `Error calling generative API for request ID ${requestId}. Data: ${data}`,
      );
      return rejectWithValue(data);
    }

    const streamReader = response.body?.getReader();
    if (!streamReader) {
      return rejectWithValue(
        `Unable to get reader from response body, request ID: ${requestId}`,
      );
    }

    while (true) {
      const { done, value } = await streamReader.read();
      if (done) {
        console.info(
          `Generative stream completed for request ID: ${requestId}.`,
        );
        break;
      }

      const assistantResponseText = new TextDecoder().decode(value);

      const [responseParseError, assistantResponseObject] =
        convertAssistantResponseTextToObject(assistantResponseText, requestId);
      if (responseParseError) {
        console.error(
          `Error parsing assistant, request ID: ${requestId}, error: ${responseParseError}`,
        );
        continue;
      }
      if (!assistantResponseObject) {
        continue;
      }

      const isSystemMessage =
        (assistantResponseObject.data as SystemMessageData).system_message !==
        undefined;
      if (isSystemMessage) {
        const systemMessageData =
          assistantResponseObject.data as SystemMessageData;

        console.info(
          `System message received for request ID: ${requestId}: `,
          systemMessageData,
        );

        if (systemMessageData.system_message === 'usage') {
          console.info(
            `Request usage info for request ID ${requestId}: ${JSON.stringify(
              systemMessageData.usage || {},
            )}`,
          );
        }

        continue;
      }

      let assistantText = '';

      // user message is simply the action the service
      // is currently taking, NOT the end users message
      // i.e "Searching KN Materials for xyz"
      const isUserMessage =
        (assistantResponseObject.data as UserMessageData).user_message !==
        undefined;
      if (isUserMessage) {
        assistantText = `${
          (assistantResponseObject.data as UserMessageData).user_message
        } \n\n`;
      }

      const isGenerativeCompletion = isGenerativeCompletionData(
        assistantResponseObject.data,
      );
      if (isGenerativeCompletion) {
        assistantText = getAssistantTextFromCompletionData(
          assistantResponseObject.data as GenerativeCompletionData,
        );
      }

      const currentState = getState() as RootState;
      const currentGenerativeMessages = currentState.generative.messages;
      const lastMessage =
        currentGenerativeMessages[currentGenerativeMessages.length - 1];

      handleAssistantResponse(lastMessage, assistantText, dispatch);
    }

    const functionEndTime = new Date().getTime();
    const elapsedFunctionTime = functionEndTime - functionStartTime;
    console.info(
      `Send Generative Message complete. Entire process took ${elapsedFunctionTime} milliseconds to complete for request ID: ${requestId}`,
    );

    return userQuery;
  },
);

const handleAssistantResponse = (
  lastMessage: GenerativeMessage,
  assistantResponseText: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  try {
    if (lastMessage?.role === GenerativeUserRoles.Assistant) {
      const concatenatedMessage = lastMessage.content + assistantResponseText;
      dispatch(popLastGenerativeMessage());
      dispatch(
        updateGenerativeStreamData({
          content: concatenatedMessage,
          role: GenerativeUserRoles.Assistant,
        }),
      );
    } else {
      dispatch(
        updateGenerativeStreamData({
          content: assistantResponseText,
          role: GenerativeUserRoles.Assistant,
        }),
      );
    }
  } catch (error) {
    console.error(
      `Error handling assistant response in handleAssistantResponse: ${error}`,
    );
  }
};

const convertAssistantResponseTextToObject = (
  content: string,
  requestId: string,
): [Error | null, GenerativeMessageResponseData | null] => {
  try {
    /*
    Content may come as mutliple lines, such as:
    
    data: {"id": "chatcmpl-8Dg9MRGaDACCaiY13b2zyGOxVPUHV", "choices": [{"delta": {"function_call": {"arguments": "{\n"}}}], "chunk_instance": 3}
    data: {"id": "chatcmpl-8Dg9MRGaDACCaiY13b2zyGOxVPUHV", "choices": [{"delta": {"function_call": {"arguments": " "}}}], "chunk_instance": 4}
    data: {"id": "chatcmpl-8Dg9MRGaDACCaiY13b2zyGOxVPUHV", "choices": [{"delta": {"function_call": {"arguments": " \""}}}], "chunk_instance": 5}
    data: {"id": "chatcmpl-8Dg9MRGaDACCaiY13b2zyGOxVPUHV", "choices": [{"delta": {"function_call": {"arguments": "queries"}}}], "chunk_instance": 6}
    
    For this reason, we need to ensure we convert each line to a JSON object.
    */
    const lines = content.split('\n');

    let mergedObject: GenerativeMessageResponseData | null = null;

    lines.forEach((line) => {
      // skip empty lines
      if (!line) {
        return;
      }

      const object = extractGenerativeMessageDataFromContentString(line);
      if (!object) {
        console.log(
          `Unable to parse. Line: ${line}, request ID: ${requestId}. Skipping...`,
        );
        return;
      }

      // skip function_calls
      if (isGenerativeCompletionData(object.data)) {
        const isFunctionCall =
          object.data.choices[0]?.delta?.function_call !== undefined;
        if (isFunctionCall) {
          console.log(
            `Skipping function call. Line: ${line}, request ID: ${requestId}. Skipping...`,
          );
          return;
        }
      }

      if (!mergedObject) {
        mergedObject = object;
        return;
      }

      // merge choices if line is a generative completion
      if (
        isGenerativeCompletionData(object.data) &&
        isGenerativeCompletionData(mergedObject.data)
      ) {
        mergedObject.data.choices = mergedObject.data.choices.concat(
          object.data.choices,
        );
        return;
      }
    });

    return [null, mergedObject];
  } catch (error) {
    console.error(
      'Error parsing data in convertAssistantResponseTextToObject: ',
      error,
      requestId,
    );

    return [error as Error, null];
  }
};

const extractGenerativeMessageDataFromContentString = (
  content: string,
): GenerativeMessageResponseData | null => {
  try {
    const data = content.replace('data:', '').trim();
    const parsedData = JSON.parse(data) as
      | SystemMessageData
      | GenerativeCompletionData;
    return { data: parsedData };
  } catch (error) {
    console.error(
      'Error parsing data in extractGenerativeMessageDataFromContentString: ',
      error,
    );
    return null;
  }
};

const getAssistantTextFromCompletionData = (
  data: GenerativeCompletionData,
): string => {
  const choices = data.choices;
  let assistantText = '';

  choices.forEach((choice) => {
    if (choice.delta?.content) {
      assistantText += choice.delta.content;
    }
  });

  return assistantText;
};

const isGenerativeCompletionData = (
  data: any,
): data is GenerativeCompletionData => {
  return (data as GenerativeCompletionData).choices !== undefined;
};
