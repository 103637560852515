import DefaultLayout from 'components/Layout/DefaultLayout';
import SearchPageContent from 'components/SearchPageContent';
import React from 'react';

const SearchPage: React.FC = () => {
  return (
    <div data-testid='search-page'>
      <DefaultLayout>
        <SearchPageContent />
      </DefaultLayout>
    </div>
  );
};

export default SearchPage;
