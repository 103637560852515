import CenteredImageWithText from 'components/CenteredImageWithText';
import { DynamicWrapperProps, getRenderProps } from 'utils/workspace';

const CenteredImageWithTextDynamicWrapper: React.FC<DynamicWrapperProps> = ({
  requiredRenderParams,
  retrievalMaterialData,
}) => {
  const props = getRenderProps(requiredRenderParams, retrievalMaterialData);

  if (!props.icon || !props.text1 || !props.text2) {
    console.error(
      `CenteredImageWithTextDynamicWrapper: image or texts are missing. image: ${props.icon}, texts: ${props.text1} , ${props.text2}`,
    );
    return null;
  }

  return (
    <CenteredImageWithText
      icon={props.icon}
      text1={props.text1}
      text2={props.text2}
    />
  );
};

export default CenteredImageWithTextDynamicWrapper;
