import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { sendGenerativeMessage } from './api';
import { GenerativeMessage, GenerativeState } from './typings';

const initialState: GenerativeState = {
  messages: [],
  lastReceivedUserQuery: '',
  status: 'idle',
  error: null,
};

const generativeSlice = createSlice({
  name: 'generative',
  initialState,
  reducers: {
    updateGenerativeStreamData: (state, action) => {
      const generativeMessage: GenerativeMessage = action.payload;
      state.messages.push(generativeMessage);
    },
    popLastGenerativeMessage: (state) => {
      state.messages.pop();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendGenerativeMessage.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(sendGenerativeMessage.fulfilled, (state, action) => {
      state.lastReceivedUserQuery = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(sendGenerativeMessage.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload as Error;
    });
  },
});

export const { updateGenerativeStreamData, popLastGenerativeMessage } =
  generativeSlice.actions;

// selectors
export const selectGenerativeMessages = (state: RootState) =>
  state.generative.messages;

export const selectGenerativeStatus = (state: RootState) =>
  state.generative.status;

export const selectGenerativeError = (state: RootState) =>
  state.generative.error;

export const selectGenerativeLastReceivedUserQuery = (state: RootState) =>
  state.generative.lastReceivedUserQuery;

export default generativeSlice.reducer;
