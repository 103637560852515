import DateRangePicker from 'components/DateRangePicker';
import Icon, { IconSize } from 'components/Icon';
import CheckBoxDynamicWrapper from 'dynamic/CheckBoxDynamicWrapper';
import { useTypedSearchParams } from 'hooks/useSearchParams';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { getWorkspaceConfig } from 'redux/App/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { conductRetrievalSearch } from 'redux/Retrieval/api';
import {
  selectRetrievalFilters,
  selectRetrievalResultsCount,
} from 'redux/Retrieval/slice';
import {
  RetrievalFilter,
  RetrievalFilterValues,
} from 'redux/Retrieval/typings';

import { FilterAccordionItem } from './FilterAccordionItem';
import * as Styled from './FilterPanel.styles';

const formatDateForRetrievalFilter = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const formatFilterTitle = (filterTitle: string): string => {
  const formattedFilterTitle = filterTitle.replace(/\s\(\d+\)/, '').trim();
  return formattedFilterTitle;
};

const FilterPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useTypedSearchParams();

  const retrievalResultsCount = useAppSelector(selectRetrievalResultsCount);
  const retreivalFilters = useAppSelector(selectRetrievalFilters);
  const workspace = useAppSelector(getWorkspaceConfig);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleFilterReset = () => {
    searchParams.deleteSearchParamByKey('filters');
    setStartDate(null);
    setEndDate(null);
  };

  const handleSubmitSearchTerm = () => {
    dispatch(conductRetrievalSearch([searchParams, workspace.search.filters]));
  };

  return (
    <div>
      <Styled.FilterPanelTitleContainer className='d-flex flex-row align-items-center justify-content-between pt-3 pb-3'>
        <div className='d-flex align-items-center gap-2 left-content'>
          <Icon size={IconSize.md} name='filter' />
          <span className='label fw-medium'>Filters</span>
        </div>
        <div className='right-content'>
          {retrievalResultsCount} Results Found
        </div>
      </Styled.FilterPanelTitleContainer>

      {workspace.search.filters.map((filter) => {
        switch (filter.filterType) {
          case 'date': {
            const handleMonthChange = (date: Date) => {
              const hasStartDateSelected =
                startDate && (!endDate || date >= startDate);

              if (hasStartDateSelected) {
                setEndDate(date);
              } else {
                setStartDate(date);
                setEndDate(null);
              }

              const formattedStartDateString = formatDateForRetrievalFilter(
                startDate as Date,
              );

              const formattedEndDateString = formatDateForRetrievalFilter(
                endDate as Date,
              );

              setSearchParams(
                {
                  filters: {
                    [`start${filter.sourceDataKey}`]: [
                      {
                        name: filter.sourceDataKey,
                        type: 'date',
                        predicate: '>=',
                        filter_values: [formattedStartDateString],
                      },
                    ],
                    [`end${filter.sourceDataKey}`]: [
                      {
                        name: filter.sourceDataKey,
                        type: 'date',
                        predicate: '<=',
                        filter_values: [formattedEndDateString],
                      },
                    ],
                  },
                },
                true,
              );
            };

            // if searchParams filter contains start date but state does not, set it
            if (
              searchParams.filters.hasOwnProperty(
                `start${filter.sourceDataKey}`,
              )
            ) {
              const startDateFilter =
                searchParams.filters[`start${filter.sourceDataKey}`];

              if (startDateFilter && !startDate) {
                const startDate = startDateFilter[0].filter_values[0];
                setStartDate(new Date(startDate));
              }
            }

            // if searchParams filter contains end date but state does not, set it
            if (
              searchParams.filters.hasOwnProperty(`end${filter.sourceDataKey}`)
            ) {
              const endDateFilter =
                searchParams.filters[`end${filter.sourceDataKey}`];
              if (endDateFilter && !endDate) {
                const endDate = endDateFilter[0].filter_values[0];
                setEndDate(new Date(endDate));
              }
            }

            return (
              <FilterAccordionItem key={filter.id} label={filter.label}>
                {retreivalFilters.length > 0 && (
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    handleStartDateChange={(date) => setStartDate(date)}
                    handleEndDateChange={(date) => setEndDate(date)}
                    handleMonthChange={handleMonthChange}
                  />
                )}
              </FilterAccordionItem>
            );
          }

          case 'list': {
            const currentRetrievalFilter = retreivalFilters.find(
              (f: RetrievalFilter) => f.name === filter.sourceDataKey,
            );

            if (!currentRetrievalFilter?.values?.length) {
              return (
                <FilterAccordionItem
                  key={filter.id}
                  label={filter.label}
                ></FilterAccordionItem>
              );
            }

            return (
              <FilterAccordionItem key={filter.id} label={filter.label}>
                {currentRetrievalFilter?.values.map(
                  (filterValue: RetrievalFilterValues) => {
                    // if in search params but not in state, check it by default
                    let checkedByDefault = false;
                    searchParams.filters[filter.sourceDataKey]?.forEach(
                      (filter) => {
                        if (
                          filter.filter_values.includes(
                            formatFilterTitle(filterValue.value),
                          )
                        ) {
                          checkedByDefault = true;
                        }
                      },
                    );

                    return (
                      <CheckBoxDynamicWrapper
                        key={filterValue.value}
                        filter={filter}
                        filterTitle={filterValue.value}
                        filterValue={formatFilterTitle(filterValue.value)}
                        type={'checkbox'}
                        isCheckedByDefault={checkedByDefault}
                        disabled={false}
                      />
                    );
                  },
                )}
              </FilterAccordionItem>
            );
          }

          case 'boolean': {
            const isChecked =
              searchParams?.filters[filter.sourceDataKey]?.[0].filter_values
                .length > 0;

            const isDisabled =
              retreivalFilters.length === 0 ||
              !retreivalFilters.find((f) => f.name === filter.sourceDataKey);

            return (
              <div
                className='py-3 px-1 border-bottom border-light-subtle'
                key={filter.label}
              >
                <CheckBoxDynamicWrapper
                  filter={filter}
                  filterTitle={filter.label}
                  type={'switch'}
                  isCheckedByDefault={isChecked}
                  filterValue={formatFilterTitle(filter.label)}
                  disabled={isDisabled}
                />
              </div>
            );
          }

          default:
            return null;
        }
      })}

      <div className='d-flex flex-row gap-2 mt-3'>
        <Button
          variant='outline-primary'
          size={'sm'}
          className='w-100 rounded-3'
          onClick={handleFilterReset}
          disabled={!Object.keys(searchParams.filters).length}
        >
          Reset
        </Button>
        <Button
          variant='outline-primary'
          size={'sm'}
          className='w-100 rounded-3'
          disabled={
            !Object.keys(searchParams.filters).length ||
            !searchParams.q ||
            retreivalFilters.length === 0
          }
          onClick={handleSubmitSearchTerm}
        >
          Apply Filter
        </Button>
      </div>
    </div>
  );
};

export default FilterPanel;
